import { nanoid } from "nanoid";
import CustomError from "../components/CustomError";

const REQUEST_TIMEOUT = 5000;
const SUPPORTED_METHODS = new Set(["POST", "PUT", "DELETE", "PATCH", "GET"]);

/**
 * Базовый клас для API запросов
 * 
 * @class Server
 */
class Server {
  /**
   * Список сервисов, с которыми работает UI
   *
   */
  static servises = {
    local:            "/",
    composer:         "/services/app.composer",
    auth:             "/services/transport.gateway",
    gateway:          "/services/transport.gateway",
    files:            "/services/storage.files",
    journal:          "/services/common.journal",
    account:          "/services/common.users",
    users:            "/services/common.users",
    admin:            "/services/common.admin",
    styles:           "/services/common.styles",
    notifications:    "/services/common.notifications",
    library:          "/services/app.library",
    "pharma-import":  "/services/impexp.pharma-urs",
    "ros-import":     "/services/impexp.reqs",
    import:           "/services/impexp.reqs",
    text:             "/services/app.text",
    binder:           "/services/app.binder",
    kinds:            "/services/app.kindsattrs",
    tasks:            "/services/app.tasks",
    tracer:           "/services/app.tracer",
    tracer2:          "/services/app.tracer1",
    vcs:              "/services/app.sourcecode",
    mathml:           "/services/impexp.mathml",
    tables:           "/services/app.tables",
    approvals:        "/services/app.approvals",
    workflow:         "/services/app.workflow",
    "ai-req-quality": "/services/thirdparty.aireqquality"
  };

  // временно убираю это, тк это приводит к конфликту с webscoket  devServer
  // const protocol = 'ws:'
  static wsServer = `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.hostname}:${
    window.location.port
  }${Server.servises.gateway}/ws`;
  // static wsServer = "ws://localhost:8100/ws";

  /**
   * Констурктор
   * 
   * @param {RootStore} rootStore базовое хранилище
   */
  constructor(root) {
    this.rootStore = root;
    this.storage = window.localStorage;
  }

  /**
   * Формирование строкового предтставления по шаблону
   * 
   * @param {Array<String>} strings набор строк
   * @param  {...any} keys набор ключей
   * @returns {String} строковое представление
   */
  template(strings, ...keys) {
    return function parse(...values) {
      const dict = values[values.length - 1] || {};
      const result = [strings[0]];
      keys.forEach((key, i) => {
        let value = "";
        if (!!values[key] && typeof values[key] === "object") {
          value = values[key].message;
        } else {
          value = Number.isInteger(key) ? values[key] : dict[key];
        }

        result.push(value, strings[i + 1]);
      });
      return result.join("");
    };
  }

  /**
   * Сформировать полный URL сервиса
   * 
   * @param {String} service название сервиса из Server.services
   * @param {String|Function} url url внутри сервиса или функция, которая сформирует url с ипользованием 
   *    параметра `params`
   * @param {Object} params набор парметров для функции формирования `url` внутри сервиса
   * 
   * @returns {String} возвращается url сервиса
   */
  getServiceUrl(service, url, params) {
    const server = `${Server.servises[service].trimLeft("/")}/`;
    let u = url;
    if (typeof url === "function") {
      u = url(...params);
    }

    u = u.trimRight("/");
    // const u = `${url.trimRight("/")}`;
    return server + u;
  }

  /**
   * Обрабатывает ответ
   * 
   * @param  {String} type - тип запроса(ключ обьекта methods)
   * @param  {Object} resp - response Obj для обработки
   * @param  {String} traceId - id запроса
   * @returns {Object}        Объект данных
   */
  async handleResponse(type, resp, traceId) {
    const method = this.methods[type];
    const redirect = resp.headers.get("Location");
    if (redirect) {
      window.location.replace(redirect);
      throw new Error(this.methods[type].textError("Redirect"));
    }

    const bearerToken = resp.headers.get("Authorization");
    const operator = resp.headers.get("Operator");

    let data;
    const status = resp.status;
    const contentType = resp.headers && resp.headers.get("Content-Type");
    const isJSON = contentType && contentType.includes("json");
    const isXML = contentType && contentType.includes("xml");

    if (status === 200 || status === 201 || status === 204) {
      if (status !== 204) {
        if (type === "file" || method.responseIsBlob) {
          const blob = await resp.blob();
          return URL.createObjectURL(blob);
        }
        if (isXML) {
          data = await resp.text();
        } else {
          const body = await resp[isJSON ? "json" : "text"]();

          if (!body.success && body.errors) {
            let errors = [];
            if (Array.isArray(body.errors)) {
              errors = body.errors.map((error) => {
                return error.message || error;
              });
            } else {
              errors = [body.errors.message];
            }
            throw new Error(this.methods[type].textError(errors.join(", ")));
          } else {
            if (typeof body === "object") {
              data = body.data !== undefined ? body.data : body;
            } else {
              data = body;
            }
          }
        }
      }
    } else {
      let errors = [];
      const body = await resp[isJSON ? "json" : "text"]();
      let detail = body.errors && body.errors.detail && body.errors.detail.detail && body.errors.detail.detail.detail ? 
        body.errors.detail.detail.detail : "";
      if (Array.isArray(body.errors)) {
        errors = body.errors.map((error) => {
          return error.message || error;
        });
      } else {
        if (body.errors) {
          errors = [body.errors.message];
        }
        
        if (body.detail) {
          if (typeof(body.detail) === "string") {
            errors = [body.detail];
            detail = body.detail;
          }
          
          if (typeof(body.detail) === "object") {
            const txt = body.detail.detail || body.detail.title;
            detail = body.detail.detail;
            if (txt) {
              errors = [txt];
            }
          }
        }
      }

      let error = "Ошибка в запросе.";

      const statuses = {
        400: "Неверный запрос. Пожалуйста, проверьте введенные данные и попробуйте снова.",
        401: "Не верный логин или пароль.",
        403: "У вас недостаточно прав для выполнения этого действия. Пожалуйста, обратитесь к администратору для получения доступа.",
        404: "Ресурс не найден.",
        405: "Вызываемый метод не поддерживается.",
        408: "Превышено время ожидания запроса",
        500: "Внутренняя ошибка сервера.",
        502: "Запрос был неверно обработан."
      };
      if (statuses[status]) {
        error = statuses[status];
      }
      error = errors.length > 1 ? errors.join(", ") : error;
      const textError = await this.methods[type].textError(error, resp);
      const err = new CustomError(textError, status, traceId, detail);
      err.name = status;
      throw err;
    }
    if (bearerToken && operator) {
      return {
        bearerToken,
        operator,
        data
      };
    } else if (method.returnHeaders) {
      return {
        headers: resp.headers,
        data
      };
    } else {
      return data;
    }
  }

  /**
   * Запрос к сервису
   * 
   * @param  {String}  type       - тип запроса(ключ обьекта methods)
   * @param  {Object}  data       - данные для отправки на сервер
   * @param  {Boolean} isFormData - параметр показывает нужно ли данные оборачивать в formData
   * @param  {Boolean} putToCache - параметр показывает, что нужно ответ запроса положить в кэш
   * @param  {Boolean} getFromCache - параметр показывает, что нужно запросить данные из кэша
   * @returns {Promice}              возвращает Promice, тк async
   */
  async request(type, requestData, isFormData = false, putToCache = false, getFromCache = false) {
    const method = this.methods[type];
    const userToken = this.rootStore.accountStore.token;
    let token = userToken;
    if (requestData && requestData.code) {
      token = `Basic ${requestData.code}`;
    } else if (!userToken && !requestData.code) {
      throw new Error(this.methods[type].textError("Отсутствует токен."));
    }
    let data = requestData;
    let url = method.url;
    if (typeof url === "function") {
      url = method.url(requestData.params);
      data = requestData.data;
    }

    const traceId = nanoid(10); // shortUid примерного вида - "VSCCeUk70R"
    const headers = {
      Authorization: token,
      "Trace-Id":    traceId    
    };

    // id операции, которая требует определения нескольких запросов для выполнения
    if (requestData && requestData.operationId) {
      headers["Operation-Id"] = requestData.operationId;
    }
    const options = {
      method:  method.method || "POST",
      timeout: 10000,
      headers
    };

    if (SUPPORTED_METHODS.has(method.method)) {
      let body = JSON.stringify(data);

      if (method.noJSONStringify) {
        body = data;
      }

      if (isFormData) {
        const formData = new FormData();
        for (const prop in data) {
          if (Object.prototype.hasOwnProperty.call(data, prop)) {
            if (typeof data[prop] === "object") {
              if (data[prop] && data[prop].length) {
                data[prop].forEach((item) => {
                  formData.append(prop, item);
                });
              } else {
                formData.append(prop, JSON.stringify(data[prop]));
              }
            } else {
              formData.append(prop, data[prop]);
            }
          }
        }
        body = formData;
      } else {
        options.headers = {
          ...options.headers,
          Accept:         method.accept || "application/json",
          "Content-Type": method.contentType || "application/json"
        };
      }

      options.body = body;
    }

    // уникальный индификатор запроса
    // Магия для fetch timeout
    // Создаем текст ошибки таймаута
    const errText = method.textError("Превышено время ожидания запроса");
    let timer;
    // если у метода задан свой таймаут берем его, если нет то из константы
    const time = method.timeout ? method.timeout : REQUEST_TIMEOUT;
    // создаем промис с таймером
    // по таймеру вызовется reject с нашим текстом ошибки
    const timeout = new Promise((resolve, reject) => {
      // timer = setTimeout(reject, time, errText);
      timer = setTimeout(() => {
        this.rootStore.uiStore.removeBusyRequest(traceId);
        reject(new CustomError(errText, 408));
      }, time);
    });

    let response;
    let result;
    // запускаем в try/catch гонку промисов
    // какой быстрее наступит тот и обрабатываем

    try {
      if (getFromCache) {
        result = this.storage.getItem(`${type}-${JSON.stringify(requestData)}`);
        result = result && JSON.parse(result);
      }
      if (!result || !getFromCache) {
        response = await Promise.race([timeout, fetch(url, options)]);
        // если код пришел сюда значит промиз fetch отресолвился первым
        // сбрасываем таймер
        // передаем результаты обработчику
        clearTimeout(timer);
        this.rootStore.uiStore.addBusyRequest(traceId, url, options);
        result = await this.handleResponse(type, response, traceId);
        this.rootStore.uiStore.removeBusyRequest(traceId);
      } else {
        clearTimeout(timer);
      }
      if (putToCache && (!method.contentType || method.contentType === "application/json")) {
        this.storage.setItem(`${type}-${JSON.stringify(requestData)}`, JSON.stringify(result));
      }
    } catch (error) {
      this.rootStore.uiStore.removeBusyRequest(traceId);
      // timeout бросает исключение, catch() только для него, в противном
      // случае catch() не произойдет
      // Если error уже является CustomError, повторно создавать не нужно
      if (error instanceof CustomError) {
        throw error;
      }
      // В противном случае создаем новый экземпляр CustomError
      throw new CustomError(error.message, error.status, traceId);
    }

    return result;
  }
}

export default Server;
