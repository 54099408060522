import { action, computed, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class Rubric extends BaseBoxClass {
  @observable
  captionId = null;
  @observable
  isNew = false;
  @observable
  creationPromise = null;
  @observable
  numbers = [];

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data && data.header) {
      const caption = this.getItemById(data.header);
      caption.setParent(this);
      this.captionId = data.header;
    }
    if (data.isNew) {
      this.creationPromise = this.persistCreate();
    }
    // задаем номера рубрики, пришедшие от текстового сервиса
    this.numbers = data.number;
  }

  @action
  async createCaption(text, noEditing = false) {
    if (!this.store) {
      return null;
    }
    const id = this.store.getUid();
    this.captionId = id;
    const caption = this.store.createItem(
      {
        uid:   id,
        class: "text.element.Indent",
        isNew: true
      },
      this
    );
    await caption.creationPromise;
    const chunk = caption.createChunk();
    await chunk.creationPromise;
    await chunk.setValue(text);
    caption.addChunk(chunk);
    if (!noEditing) {
      this.setEditing();
    }
  }

  @action
  setCaptionId(id) {
    this.captionId = id;
  }


  @action
  getPrevId(id) {
    if (id === this.captionId) {
      return null;
    }
    const index = this.getItemIndexById(id);
    return index > 0 && this.idsArray.length
      ? this.idsArray[index - 1]
      : this.captionId;
  }

  @action
  getNextId(id) {
    if (id === this.captionId && this.idsArray.length) {
      return this.idsArray[0];
    }
    const index = this.getItemIndexById(id);
    return this.idsArray.length && index < this.idsArray.length - 1
      ? this.idsArray[index + 1]
      : null;
  }

  @action
  async setEditing() {
    let itemId = this.captionId;
    if (this.store.delta < 0 && this.idsArray.length) {
      itemId = this.idsArray[this.idsArray.length - 1];
    }
    const item = this.getItemById(itemId);
    await(item && item.setEditing());
  }

  @action
  delete(id, stopPropagation = false, noPersist = false) {
    if (this.captionId === id) {
      this.captionId = null;
    }
    this.deleteItemId(id);
    this.store.deleteItemById(id, noPersist);
  }

  @computed
  get number() {
    // если номер рубрики пришел от сервиса, то выводим его
    if (!this.parent && this.numbers && this.numbers.length > 0) {
      return this.numbers.join(".");
    }
    // иначе считаем сами внутири документа
    return this.parent &&
      this.parent.number !== undefined &&
      this.parent.number !== null
      ? `${this.parent.number}.${this.indexNumber}`
      : this.indexNumber;
  }

  @computed
  get caption() {
    return this.getItemById(this.captionId);
  }

  @computed
  get isRubric() {
    return true;
  }

  @computed
  get captionTitle() {
    return `${(this.caption && this.caption.title) || ""}`;
  }

  @computed
  get title() {
    return `${this.number} ${(this.caption && this.caption.title) || ""}`;
  }

  @computed
  get hasChildRubrics() {
    let hasChildRubrics = false;
    this.items.forEach((item) => {
      if (item.isRubric) {
        hasChildRubrics = true;
      }
    });
    return hasChildRubrics;
  }

  @computed
  get rubrics() {
    const arr = [];
    this.items.forEach((item) => {
      if (item.isRubric) {
        arr.push(item);
      }
    });
    return arr;
  }

  @computed
  get nextSiblingNumber() {
    return this.parent.number
      ? `${this.parent.number}.${this.indexNumber + 1}`
      : this.indexNumber + 1;
  }

  @computed
  get nextChildNumber() {
    return `${this.number}.1`;
  }

  @computed
  get hasElements() {
    let hasElements = false;
    this.items.forEach((item) => {
      if (!(item && item.isRubric)) {
        hasElements = true;
      }
    });
    return hasElements;
  }

  @computed
  get hasChildren() {
    return !!this.idsArray.length;
  }

  @computed
  get defaultInnerItemsArray() {
    return this.defaultItemsArray.map((item) => {
      const availableKindsArray = [];

      if (this.allowedChildrenTypes.has(item.class)) {
        availableKindsArray.push({ kind: "type-only", title: "", icon: null });
      }
  
      if (
        this.store.typeToKindMapping[item.class] && 
        this.store.typeToKindMapping[item.class].length
      ) {
        this.store.typeToKindMapping[item.class].forEach((kind) => {
          if (this.allowedChildrenKinds.has(kind.kind)) {
            availableKindsArray.push(kind);
          }
        });
      }

      return { ...item, availableKindsArray, isParent: true, ancorId: this.uid };
    });
  }

  @computed
  get defaultItemsArray() {
    return [
      {
        class: "text.element.Indent",
        icon:  "aggr-M"
      },
      { class: "text.element.Enumeration", icon: "list-mark-M" },
      { class: "text.element.Table", icon: "table-M" },
      {
        class: "text.element.Picture",
        icon:  "image-M"
      },
      {
        class: "text.element.CodeBlock",
        icon:  "code-M"
      },
      {
        class: "text.element.Formula",
        icon:  "formula-M"
      }
    ];
  }

  @computed
  get hierarchyItemsArray() {
    const availableKindsArray = [];

    if (this.parent.allowedChildrenTypes.has("text.container.Rubric")) {
      availableKindsArray.push({ kind: "type-only", title: "", icon: null });
    }

    if (
      this.store.typeToKindMapping["text.container.Rubric"] && 
      this.store.typeToKindMapping["text.container.Rubric"].length
    ) {
      this.store.typeToKindMapping["text.container.Rubric"].forEach((kind) => {
        if (this.parent.allowedChildrenKinds.has(kind.kind)) {
          availableKindsArray.push(kind);
        }
      });
    }


    const array = [
      {
        class:     "text.container.Rubric",
        icon:      "paragraph-M",
        availableKindsArray,
        title:     this.nextSiblingNumber,
        isSibling: true,
        ancorId:   this.uid
      }
    ];
    if (!this.isLast) {
      return array;
    }
    return [].concat(this.parent.hierarchyItemsArray, array);
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [];
    const availableKindsArray = [];

    if (this.allowedChildrenTypes.has("text.container.Rubric")) {
      availableKindsArray.push({ kind: "type-only", title: "", icon: null });
    }

    if (
      this.store.typeToKindMapping["text.container.Rubric"] && 
      this.store.typeToKindMapping["text.container.Rubric"].length
    ) {
      this.store.typeToKindMapping["text.container.Rubric"].forEach((kind) => {
        if (this.allowedChildrenKinds.has(kind.kind)) {
          availableKindsArray.push(kind);
        }
      });
    }

    if (availableKindsArray.length) {
      array.push({
        class:    "text.container.Rubric",
        availableKindsArray,
        icon:     "paragraph-M",
        title:    this.nextChildNumber,
        isParent: true,
        ancorId:  this.uid
      });
    }
    return array;
  }

  @computed
  get availableItemsArray() {
    if (this.isLocked && !this.isLockedByMe) {
      return [].concat(
        this.hierarchyItemsArray,
      );
    }
    if (this.isParentLocked && !this.isParentLockedByMe) {
      return [];
    }
    if (this.hasElements) {
      return this.defaultInnerItemsArray;
    }
    if (this.hasChildren) {
      return [].concat(
        this.innerHierarchyItemsArray,
        this.defaultInnerItemsArray
      );
    }
    const array = [].concat(
      this.hierarchyItemsArray,
      this.innerHierarchyItemsArray,
      this.defaultInnerItemsArray
    );
    return array;
  }

  @computed
  get level() {
    return this.parent && !this.parent.isStore ? this.parent.level + 1 : 1;
  }

  @computed
  get tree() {
    return {
      class:        this.className,
      className:    this.className,
      uid:          this.uid,
      number:       this.number,
      level:        this.level,
      index:        this.indexInFlatList,
      top:          this.top,
      version:      this.store.version,
      hasRelations: this.hasRelations,
      hasKinds:     this.hasKinds,
      isFocusUid:   this.isFocusUid,
      title:        this.caption ? this.caption.title : ""
    };
  }

  @computed
  get slug() {
    return "rubrics";
  }

  @computed
  get category() {
    return "rubrics";
  }

  @computed
  get output() {
    return [{
      class:       this.className,
      uid:         this.uid,
      elements:    [],
      level:       0,
      rubrics:     [],
      header:      this.captionId,
      "@position": this.position
    }, ...this.items.map((item) => {
      return item.output;
    })];
  }
}

export default Rubric;
