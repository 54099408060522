import React, { useCallback, useEffect, useState } from "react";
import { Provider, observer } from "mobx-react";
import { Components, Dnd, Notification } from "@ais3p/ui-framework";

import AppContainer from "~/core/components/AppContainer";
import Confirm from "./Confirm";
import ReloadBtn from "./ReloadBtn";
import WizardTool from "../../modules/wizard/components/WizardTool";
import { errorNotify } from "../utils";
import RootStore from "~/core/data/stores/rootStore";


import "@ais3p/ui-framework/bundle.css";
import "~/App.scss";

/**
 * Приложение АИС ППП
 * 
 * @param {Boolean} hasError признак наличия системной ошибки
 * @param {Error} error системная ошибка
 */
const App = observer(({ hasError, error }) => {
  const [rootStore] = useState(new RootStore());
  
  useEffect(() => {
    return (() => {
      rootStore.destroy();  
    });
  }, [rootStore]);

  const onReload = useCallback(() => {
    const { accountStore } = rootStore;
    accountStore.clearUserData();
    window.location.reload(true);
  }, [rootStore]);

  if (hasError) {
    return (
      <div className="App">
        <div id="ais-app-root" className="app-container login error"></div>
        <div className="overlay-blur visible"></div>
        <div className={"app-error visible uif-modal-window "}>
          <div className="error-header">{"Критическая ошибка"}</div>
          <div className="error-text">{error && error.message}</div>
          <div className="footer">
            <Components.Button
              key="close"
              icon="close-M"
              tooltip="Перезапустить"
              text={"Перезапустить"}
              color={"negative"} // eslint-disable-next-line
              onPress={onReload}
            />
          </div>
        </div>
      </div>
    );
  }

  const {
    userStore,
    accountStore,
    relationStore,
    marksStore,
    objectStore,
    kindsStore,
    groupsStore,
    uiStore,
    configStore,
    workflowStore,
    aboutStore,
    wsStore,
    textStore
  } = rootStore;

  const { showError, errorText, errorIsCritical, errorStatus, successText, errorDetail } = uiStore;

  const onDismissError = useCallback(() => {
    uiStore.dismissError();
  }, [rootStore]);

  const onDismissSuccess = useCallback(() => {
    uiStore.dismissSuccess();
  }, [rootStore]);

  useEffect(() => {
    if (showError && errorText) {
      const contentToShow = errorIsCritical ? <ReloadBtn onReload={onReload} errorText={errorText} /> : errorText;
      if (errorStatus === 400 || errorStatus === 404 || errorStatus ===  408 || errorStatus === 403) {
        Notification.warning(errorText, { onClose: () => {
          onDismissError(); 
        } });
      } else {
        errorNotify(contentToShow, errorDetail, onDismissError);
      }
    }
    if (successText) {
      Notification.success(successText, { onClose: () => {
        onDismissSuccess(); 
      } });
    }
  }, [showError, errorText, errorIsCritical, errorStatus, successText, errorDetail]);  

  return (
    <Dnd.Provider>
      <Provider
        rootStore={rootStore}
        uiStore={uiStore}
        userStore={userStore}
        accountStore={accountStore}
        relationStore={relationStore}
        marksStore={marksStore}
        objectStore={objectStore}
        kindsStore={kindsStore}
        groupsStore={groupsStore}
        configStore={configStore}
        workflowStore={workflowStore}
        wsStore={wsStore}
        aboutStore={aboutStore}
        textStore={textStore}
      >
        <div className={`App ${uiStore.showConfirm ? "confirm" : ""}`}>
          <AppContainer />
          <div className="overlay-blur"></div>
          <Notification.Area />
          <Confirm />
          <WizardTool />
        </div>
      </Provider>
    </Dnd.Provider>
  );
});

export default App;
