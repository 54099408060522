import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import File from "../../viewer/components/File";
import { Components, Modal, Dnd, Notification } from "@ais3p/ui-framework";
import ViewerTool from "~/modules/viewer/tools/ViewerTool";
import { DND_FILE_TYPE } from "~/core/constants/DnD";
import useStores from "~/core/utils/useStores";
import getTimePeriod from "../../../core/utils/getTimePeriod";

const InlinePicture = observer(({ data, measure, title, readOnly }) => {
  const { uid, value, isEditingPicture, diffClass, isNewPictureDialog, originTooltip, originClass } = data;
  const [loaded, setLoaded] = useState(null);
  const [isShowPictureInModal, setIsShowPictureInModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [newImgBlob, setNewImgBlob] = useState(false);
  const { rootStore } = useStores();

  const onLoad = useCallback((data) => {
    setLoaded(data);
    measure && measure();
  });

  useEffect(() => {
    onLoad(null);
  }, [value]);

  const onClickPicture = useCallback((e) => {
    if (readOnly) {
      e.stopPropagation();
    }
    
    setIsShowPictureInModal(!!value && !isEditingPicture);
  }, [value, isEditingPicture, readOnly]);

  const onCancelShowImage = useCallback((e) => {
    // чтобы событие onClick не уходило за пределы модального окна
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsShowPictureInModal(false);
  }, []);

  useEffect(() => {
    setNewImgBlob(null);
    setIsEditMode(false);
  }, [isShowPictureInModal]);

  const [src, setSrc] = useState(null);
  const refInput = useRef();

  const unsetIsEditingPicture = useCallback(
    (isOk = false) => {
      data.setEditingPicture();
      setSrc(null);
      setNewImgBlob(null);      
      if (isOk !== true && isNewPictureDialog) {
        data && data.parent && data.parent.selfDestruct(true);
      }
    }, [isNewPictureDialog]);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];
    setIsProcessing(true);
    try {
      const src = await data.uploadFile(file);
      setSrc(src);
    } catch (ex) {
      console.warn(ex.message);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  const onOk = useCallback(async() => {
    if (newImgBlob) {
      const uid = await saveImgBlob(newImgBlob);
      if (uid) {
        await data.setValue(uid);
        setNewImgBlob(null);
      }
    } else {
      await data.setValue(src);
    }

    setIsEditMode(false);
    unsetIsEditingPicture(true);
  }, [src, newImgBlob]);

  const onBrowse = useCallback(() => {
    if (refInput && refInput.current) {
      refInput.current.value = null;
      refInput.current.click();
    }
  }, [refInput]);

  const onToggleEditMode = useCallback((mode) => {
    setIsEditMode(mode);
  }, []); 

  const onCancelEditImage = useCallback((e) => {
    // чтобы событие onClick не уходило за пределы модального окна
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    setIsEditMode(false);
    setNewImgBlob(null);
  }, []);  

  const onImgChange = useCallback((imgBlob) => {
    setNewImgBlob(imgBlob);
  }, []); 

  const onSaveImage = useCallback(async(e) => {
    // чтобы событие onClick не уходило за пределы модального окна
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    const uid = await saveImgBlob(newImgBlob);
    if (uid) {
      await data.setValue(uid);
      setNewImgBlob(null);
      setIsShowPictureInModal(false);
    }    
  }, [newImgBlob, data]);

  const saveImgBlob = async(imgBlob) => {
    if (!imgBlob) {
      return null;
    }
    setIsProcessing(true);
    try {
      newImgBlob.lastModifiedDate = new Date();
      newImgBlob.name = data.uid;
      const uid = await data.uploadFile(newImgBlob);
      return uid;
    } catch (ex) {
      rootStore.onError(ex);
    } finally {
      setIsProcessing(false);
    }

    return null;
  };

  const onImgEditorClick = useCallback((e) => {
    // чтобы событие onClick не уходило за пределы модального окна
    e.stopPropagation();
  }, []);

  /**
   * Делаем проверку на возможность Drop файла
   */
  const canDropInEditor = useCallback((dndItem, monitor) => {
    return monitor.getItemType() === DND_FILE_TYPE;
  }, []);

  /**
   * Обработчик Drop файла в окно выбора изображения
   */
  const onDropInEditor = useCallback(async(dndItem, monitor) => {
    if (monitor.getItemType() !== DND_FILE_TYPE) {
      return;
    }

    if (dndItem.files && dndItem.files.length > 0) {
      const file = dndItem.files[0];
      const { type } = file;
      if (!(type && type.indexOf("image/") === 0)) {
        Notification.warning("В текст можно переносить только файлы в формате изображений.", { autoClose: 2500 });
        return;
      }
      setIsProcessing(true);
      try {
        const src = await data.uploadFile(file);
        setSrc(src);
      } catch (ex) {
        console.warn(ex.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }, [data]);

  const selectImgmodalButtons = useMemo(() => {
    return [
      ( 
        <Components.Button
          key="add"
          text="Добавить"
          icon="plus-M"
          onPress={onOk}
          isDisabled={!src || (isEditMode && !newImgBlob)}
          color="action"
        />
      ), (
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={isEditMode ? onCancelEditImage : unsetIsEditingPicture}
          color="negative"
        />
      )
    ];
  }, [onOk, src, unsetIsEditingPicture, isEditMode, newImgBlob]);

  const imgModalButtons = useMemo(() => {
    if (isEditMode) {
      return (
        [
          <Components.Button
            key="save"
            text="Сохранить"
            icon="save-M"
            isDisabled={!newImgBlob}
            onPress={onSaveImage}
            color="positive"
          />,
          <Components.Button
            key="cancel"
            text="Отменить"
            icon="cancel-M"
            onPress={onCancelEditImage}
            color="negative"
          />
        ]
      );
    }

    return [
      <Components.Button
        key="close"
        text="Закрыть"
        icon="close-M"
        onPress={onCancelShowImage}
        color="negative"
      />
    ];
  }, [isEditMode, newImgBlob]);

  const datestring = useMemo(() => {
    return getTimePeriod(data.originDatetime);
  }, [new Date(), data]);

  return (
    <React.Fragment>
      <span
        id={`${uid}`}
        contentEditable={false}
        className={`inline-picture ${diffClass}  ${originClass}`}
        data-tooltip={originTooltip && `${originTooltip} ${datestring}`}
        onClick={onClickPicture}
      >

        {value && (
          <File
            file={value}
            type={"image"}
            className={"inline-picture"}
            onLoad={onLoad}
            loaded={loaded}
          />
        )}
        {!value && (
          <Components.Icon
            name={"image-M"}
            data-tooltip="Нет изображения"
            data-tooltip-at="bottom"
            className="picture-image-empty-icon"
          />
        )}
      </span>
      <Modal.Window
        name="image"
        icon="image-M"
        show={isEditingPicture}
        title={"Рисунок"}
        buttons={selectImgmodalButtons}
        onKeyPressEnter={onOk}
        onKeyPressEsc={isEditMode ? onCancelEditImage : unsetIsEditingPicture}
      >
        <div 
          className={"picture-editor"}
          onClick={onImgEditorClick}
        >
          <div className={"picture-editor-image"}>
            <Dnd.Target
              drop={onDropInEditor}
              canDrop={canDropInEditor}
              accept={[DND_FILE_TYPE]}
              // className="text-flat-list-item-drop-target"
            >
              <input
                type="file"
                accept="image/*"
                ref={refInput}
                onChange={onSelectImage}
                style={{ display: "none" }}
              />
              {!src && (
                <div className={"picture-editor-image-browse-body"}>
                  <Components.Icon
                    className="picture-editor-image-empty-icon"
                    name={"image-M"}
                  />
                  <span>Вставьте или перетащите изображение сюда или</span>
                  <a
                    href="#"
                    onClick={onBrowse}
                  >
                    выберите файл изображения
                  </a>
                </div>
              )}

              {/* src && <InlinePicture data={{ uid: "temp", value: src }} />*/}
              {src && 
                <ViewerTool
                  type="image"
                  className="viewer-in-modal"
                  file={src}
                  isEditMode={isEditMode}
                  isProcessing={isProcessing}
                  onToggleEditMode={onToggleEditMode}
                  onChange={onImgChange}
                />
              }
            </Dnd.Target>
          </div>
        </div>
      </Modal.Window>
      <Modal.Window
        name="show-picture-in-modal"
        icon="image-M"
        show={isShowPictureInModal}
        title={title}
        buttons={imgModalButtons}
        onKeyPressEsc={onCancelShowImage}
        onKeyPressEnter={onSaveImage}
      >
        <ViewerTool
          type="image"
          className="viewer-in-modal"
          file={value}
          readOnly={readOnly}
          isEditMode={isEditMode}
          isProcessing={isProcessing}
          onToggleEditMode={onToggleEditMode}
          onChange={onImgChange}
        />
      </Modal.Window>
    </React.Fragment>
  );
});
export default InlinePicture;
