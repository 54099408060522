/**
 * Набор class, которые используются в АИС ППП
 */
// Репозиторий
const CLS_REPO_DIR = "sourcecode.Directory";
const CLS_REPO_FILE = "sourcecode.File";
const CLS_REPO_FILE_FUNCTION = "sourcecode.File.Function";
const CLS_REPO_BRANCHES = "sourcecode.branches";
const CLS_REPO_TAGS = "sourcecode.tags";
const CLS_REPO_GIT_REPOSITORY = "sourcecode.git.repository";
const CLS_REPO_GIT_TAG = "sourcecode.git.tag";
const CLS_REPO_GIT_BRANCH = "sourcecode.git.branch";

// Задачи
const CLS_ISSUE = "tasks.Issue";

// Work Flow
const CLS_WF_INSTANCE = "wf.Instance";

// Библиотека
const CLS_LIBRARY_TEXT_MATERIAL = "library.TextMaterial";
const CLS_LIBRARY_TEXT_VERSION = "library.TextVersion";
const CLS_LIBRARY_COLLECTION = "library.Collection";
const CLS_LIBRARY_REPOSITORY = "library.RepositoryMaterial"; 
const CLS_LIBRARY_FILE = "library.FileMaterial";

// Текстовый редактор: Тектовая форма
const CLS_TEXT_FORM_TEXT = "text.form.Text";

// Текстовый редактор: Контейнеры
const CLS_TEXT_RUBRIC = "text.container.Rubric";
const CLS_TEXT_ENUMERATION_ITEM = "text.container.EnumerationItem";
const CLS_TEXT_TABLE_ROW = "text.container.TableRow";
const CLS_TEXT_TABLE_CELL = "text.container.TableCell";

// Текстовый редактор: Элементы
const CLS_TEXT_INDENT = "text.element.Indent";
const CLS_TEXT_PICTURE = "text.element.Picture";
const CLS_TEXT_FORMULA = "text.element.Formula";
const CLS_TEXT_CAPTION = "text.element.Caption";
const CLS_TEXT_HEADER = "text.element.Header";
const CLS_TEXT_ENUMERATION = "text.element.Enumeration";
const CLS_TEXT_TABLE = "text.element.Table";
const CLS_TEXT_CODE = "text.element.CodeBlock";
const CLS_TEXT_CODE_LINE = "text.element.CodeLine";

// Текстовый редактор: Чанки
const CLS_TEXT_CHUNK_VARCHAR = "text.chunk.Varchar";
const CLS_TEXT_CHUNK_INLINE_CODE = "text.chunk.InlineCode";
const CLS_TEXT_CHUNK_INLINE_PICTURE = "text.chunk.InlinePicture";
const CLS_TEXT_CHUNK_INLINE_FORMULA = "text.chunk.InlineFormula";

// Виды
const CLS_KIND = "kindsattrs.Kind";
const CLS_KIND_CODE = "kindsattrs.Code";
const CLS_KIND_MEMBER = "kindsattrs.Member";

// Трассировка
const CLS_TRACER_BELONGS_TO = "tracer.BelongsTo";
const CLS_TRACER_CONTAINS = "tracer.Contains";

// Cвязь
const CLS_BINDER_LINK = "binder.link";

// Атритубуты
const CLS_ATTR_STRING = "kindsattrs.StringAttribute";
const CLS_ATTR_INTEGER = "kindsattrs.IntegerAttribute";
const CLS_ATTR_FLOAT = "kindsattrs.FloatAttribute";
const CLS_ATTR_ENUM = "kindsattrs.EnumAttribute";
const CLS_ATTR_BOOLEAN = "kindsattrs.BooleanAttribute";
const CLS_ATTR_DATE_TIME = "kindsattrs.DatetimeAttribute";
const CLS_ATTR_DATE = "kindsattrs.DateAttribute";

// Пользователи
const CLS_USER =  "ais3p.common.users.user";

// Файл
const CLS_FILE = "files.File";

const getClassTitle = (cls) => {
  switch (cls) {
    case CLS_REPO_DIR:
      return "Директория";
    case CLS_REPO_FILE:
      return "Файл";
    case CLS_REPO_FILE_FUNCTION:
      return "Функция в файле";

    case CLS_ISSUE: 
      return "Задача";

    case CLS_WF_INSTANCE:
      return "WF Insance";

    case CLS_LIBRARY_TEXT_MATERIAL:
      return "Текстовое представление";
    case CLS_LIBRARY_TEXT_VERSION:
      return "Версия текстового представления";
    case CLS_LIBRARY_COLLECTION:
      return "Коллекция в библиотеке";
    case CLS_LIBRARY_REPOSITORY:
      return "Репозиторий";
    case CLS_LIBRARY_FILE:
      return "Файл"; 

    case CLS_TEXT_FORM_TEXT: 
      return "Рабочий материал";
    case CLS_TEXT_RUBRIC:
      return "Рубрика";
    case CLS_TEXT_INDENT:
      return "Абзац";
    case CLS_TEXT_PICTURE:
      return "Рисунок";
    case CLS_TEXT_FORMULA:
      return "Формула";
    case CLS_TEXT_CAPTION:
      return "Подпись";
    case CLS_TEXT_HEADER:
      return "Заголовок";  
    case CLS_TEXT_ENUMERATION:
      return "Список";
    case CLS_TEXT_ENUMERATION_ITEM:
      return "Пункт списка";
    case CLS_TEXT_TABLE:
      return "Таблица";
    case CLS_TEXT_TABLE_ROW:
      return "Строка таблицы";
    case CLS_TEXT_TABLE_CELL:
      return "Ячейка таблицы";
    case CLS_TEXT_CODE:
      return "Код";
    case CLS_TEXT_CODE_LINE:
      return "Строка кода";
    case CLS_TEXT_CHUNK_VARCHAR:
      return "Чанк";
    case CLS_TEXT_CHUNK_INLINE_CODE:
      return "Inline строка кода";
    case CLS_TEXT_CHUNK_INLINE_PICTURE:
      return "Inline рисунок";
    case CLS_TEXT_CHUNK_INLINE_FORMULA:
      return "Inline формула";

    case CLS_KIND:
      return "Вид";
    case CLS_KIND_CODE:
      return "Шаблон кода Вида";
    case CLS_KIND_MEMBER:
      return "Участник Вида";

    case CLS_TRACER_BELONGS_TO:
      return "Имеет вид";
    case CLS_TRACER_CONTAINS:
      return "Содержится в";
    
    case CLS_BINDER_LINK :
      return "Связь";

    case CLS_ATTR_STRING:
      return "Атрибут типа String";
    case CLS_ATTR_INTEGER:
      return "Атрибут типа Integer";
    case CLS_ATTR_FLOAT:
      return "Атрибут типа Float";
    case CLS_ATTR_ENUM:
      return "Атрибут типа Enum";
    case CLS_ATTR_BOOLEAN:
      return "Атрибут типа Boolean";
    case CLS_ATTR_DATE_TIME:
      return "Атрибут типа DateTime";
    case CLS_ATTR_DATE:
      return "Атрибут типа Date";
    case CLS_USER:
      return "Пользолватель";
    case CLS_FILE:
      return "Файл";
    default:
      return cls;
  }
};
export {
  CLS_REPO_DIR,
  CLS_REPO_FILE,
  CLS_REPO_FILE_FUNCTION,
  CLS_REPO_BRANCHES,
  CLS_REPO_TAGS,
  CLS_REPO_GIT_REPOSITORY,
  CLS_REPO_GIT_TAG,
  CLS_REPO_GIT_BRANCH,

  CLS_ISSUE,
  CLS_WF_INSTANCE,

  CLS_LIBRARY_TEXT_MATERIAL,
  CLS_LIBRARY_TEXT_VERSION,
  CLS_LIBRARY_COLLECTION,
  CLS_LIBRARY_REPOSITORY,
  CLS_LIBRARY_FILE,

  CLS_TEXT_FORM_TEXT,
  CLS_TEXT_RUBRIC,
  CLS_TEXT_ENUMERATION_ITEM,
  CLS_TEXT_TABLE_ROW,
  CLS_TEXT_TABLE_CELL,
  CLS_TEXT_INDENT,
  CLS_TEXT_PICTURE,
  CLS_TEXT_FORMULA,
  CLS_TEXT_CAPTION,
  CLS_TEXT_HEADER,
  CLS_TEXT_ENUMERATION,
  CLS_TEXT_TABLE,
  CLS_TEXT_CODE,
  CLS_TEXT_CODE_LINE,
  CLS_TEXT_CHUNK_VARCHAR,
  CLS_TEXT_CHUNK_INLINE_CODE,
  CLS_TEXT_CHUNK_INLINE_PICTURE,
  CLS_TEXT_CHUNK_INLINE_FORMULA,

  CLS_KIND,
  CLS_KIND_CODE,
  CLS_KIND_MEMBER,

  CLS_TRACER_BELONGS_TO,
  CLS_TRACER_CONTAINS,

  CLS_BINDER_LINK,

  CLS_ATTR_STRING,
  CLS_ATTR_INTEGER,
  CLS_ATTR_FLOAT,
  CLS_ATTR_ENUM,
  CLS_ATTR_BOOLEAN,
  CLS_ATTR_DATE_TIME,
  CLS_ATTR_DATE,

  CLS_USER,
  
  CLS_FILE,

  getClassTitle
};
