import { observable, action, computed } from "mobx";
import KindsApi from "../api/KindsApi";
import Kind from "../models/Kind";
import Attr from "../models/Attr";
import KindItem from "../models/KindItem";
import TraceSchema from "~/modules/traceReporter/models/traceSchema";
import { DOMAIN_KINDS } from "~/core/constants/Domains";

/**
 * Хранилище для работы с видами и Атрибутами
 */
class KindsStore {
  // @observable
  // domain = DOMAIN_KINDS;
  /**
   * Головное хранилище UI
   * 
   * @type {RootStore}
   */
  @observable
  rootStore = null;

  /**
   * Хранилище конфишурации
   * 
   * @type {ConfigStore}
   */
  @observable
  configStore = null;

  /**
   * Признак обработки запросов для атрибутов на стороне сервисов
   * 
   * @type {Boolean}
   */
  @observable
  pendingAttributes = false;

  /**
   * Признак обработки запросов для представителей Вида на стороне сервисов
   * 
   * @type {Boolean}
   */
  @observable
  pendingItems = false;

  /**
   * Признак обработки запросов для участников Вида на стороне сервисов
   * 
   * @type {Boolean}
   */
  @observable
  pendingMembers = false;

  /**
   * Признак обработки запросов для Видов на стороне сервисов
   * 
   * @type {Boolean}
   */
  @observable
  pendingKinds = false;

  /**
   * Набор Видов, которые хранятся за ключем - uid вида
   * 
   * @type {Map<Kind>}
   */
  @observable
  kinds = new Map();

  /**
   * Набор Видов, которые хранятся за ключем - название(name) вида
   * 
   * @type {Map<Kind>}
   */
  @observable
  kindsByName = new Map();

  /**
   * Набор атрибутов, которые хранятся за ключем - название(name) атрибута
   * 
   * @type {Map<Attr>}
   */
  @observable
  attrsByName = new Map();

  /**
   * Набор атрибутов, которые хранятся за ключем - uid атрибута
   * 
   * @type {Map<Attr>}
   */
  @observable
  attrs = new Map();

  /**
   * Набор представителей Вида (KindItem) за ключем `${objectId}-${objectVersion}`
   * 
   * @type {Map<KindItem>}
   */
  @observable
  kindItems = new Map();

  /**
   * Набор участников Вида
   * 
   * @type {Map<KindMember>}
   */
  @observable
  kindMembers = new Map();

  /**
   * Набор представителей Вида (KindItem), расположенных за ключем Вида - kindUid
   * 
   * @type {Map<KindItem>}
   */
  @observable
  itemsByKindMap = new Map();

  // @observable
  // selectedKindItem;

  /**
   * Конструктор
   * @param {RootStore} root головное хранилище
   */
  constructor(root) {
    this.rootStore = root;
    this.configStore = this.rootStore.configStore;
    this.objectStore = this.rootStore.objectStore;
    this.api = new KindsApi(this.rootStore);
  }

  /**
   * Инициализация хранилища
   * 
   * @param {Bollean} withLocal получить список Вилдов из локального хранилища или от сервиса @default false
   * 
   * @returns {Promise}
   */
  @action
  async init(withLocal = false) {
    await this.getKinds(withLocal);
  }

  /**
   * Получить конифгурацию инструмента
   * 
   * @param {String} id инструмента
   * 
   * @returns {Object} конифгурация инструмента
   */
  getToolConfig(id) {
    const config = this.configStore.getToolConfig(id);
    return config;
  }

  /**
   * Получить Вид по его имени
   * 
   * @param {String} name название Вида
   * 
   * @returns {Kind} модель Вида
   */
  getKindByName(name) {
    return this.kindsByName.get(name);
  }

  /**
   * Получить Атрибут по его имени
   * 
   * @param {String} name название Атрибута
   * 
   * @returns {Attr} модель Атрибута
   */
  getAttrByName(name) {
    return this.attrsByName.get(name);
  }
  
  /**
   * Создать модель Вида
   *  
   * @param {Object} data набор данных Вида
   *  
   * @returns {Kind} модель Вида
   */
  @action
  createKind(data) {
    const allows = this.configStore.kindsAllows(data.uid);
    let allowedTypes = [];
    let allowedKinds = [];
    let allowedTasks = [];

    if (allows) {
      allowedTypes = allows.allowedTypes || [];
      allowedKinds = allows.allowedKinds || [];
      allowedTasks = allows.allowedTasks || [];
    }

    const kind = new Kind({ ...data, allowedTypes, allowedKinds, allowedTasks }, this);
    this.kinds.set(kind.id, kind);
    this.kindsByName.set(kind.name, kind);
    return kind;
  }

  // @action
  // async save() {
  //   this.currentItem.save();
  // }

  /**
   * Удалить участника Вида (KindMember) из представителя Вида (KindItem)
   * 
   * @param {String} kindUid uid Вида
   * @param {String} kindItemUid uid представителя Вида (KindItem)
   */
  @action
  deleteMemberFromKind(kindUid, kindItemUid) {
    const kindItem = this.kindItems.get(kindItemUid);
    kindItem.deleteMember(kindUid);
  }

  /**
   * Удалить участника Вида (KindMember) из общего списка учатников - kindMembers
   * 
   * @param {String} memberUid uid участника Вида
   */
  @action
  deleteKindMember(memberUid) {
    this.kindMembers.delete(memberUid);
  }

  /**
   * Удалить Вид
   * 
   * @param {String} kindUid uid Вида
   */
  @action
  deleteKind(kindUid) {
    const kindItems = this.itemsByKindMap.get(kindUid);
    if (kindItems) {
      kindItems.forEach((member) => {
        member.deleteMember(kindUid);
      });
    }
    this.itemsByKindMap.delete(kindUid);
    this.kinds.delete(kindUid);
  }

  /**
   * Сделать запрос к серсису для удаления Вида или уча
   * `${uid}-${version}`
   * @param {*} uid 
   * @param {*} parentUid 
   * @param {*} callback 
   */
  // @action
  // async delete(uid, parentUid, callback) {
  //   const kindItem = this.kindItems.get(uid);
  //   const kind = this.kinds.get(uid);
  //   if (kindItem) {
  //     await this.api.deleteKindMember(parentUid, uid);
  //     this.deleteMemberFromKind(parentUid, uid);
  //   }
  //   if (kind) {
  //     await this.api.deleteKind(uid);
  //     this.deleteKind(uid);
  //   }
  //   await callback(parentUid);
  // }

  // @action
  // addKindToItem(uid, version = 0, kindUidArray) {
  //   const item = this.kindItems.get(`${uid}-${version}`);
  //   if (item) {
  //     item.replaceKinds(kindUidArray);
  //   } else {
  //     throw new Error("Такого участника не существует");
  //   }
  // }

  /**
   * Добавить Представителя Вида (KindItem) 
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version версия объекта АИС @default 0
   * @param {String} kindUid uid Вида
   * 
   * @returns {KindItem} представитель Вида
   */
  @action
  addKind(uid, version = 0, kindUid) {
    let item = this.kindItems.get(`${uid}-${version}`);
    if (!item) {
      item = this.createEmptyItem(uid, version);
    }
    item.addKind(kindUid);
    return item;
  }

  /**
   * Изменить Виды у представителя Вида
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version версия объекта АИС @default 0
   * @param {Array<String>} kindUidArray массив uid'ов Вида
   * 
   * @returns {KindItem} представитель Вида
   */
  @action
  changeItemKinds(uid, version = 0, kindUidArray) {
    let item = this.kindItems.get(`${uid}-${version}`);
    if (!item) {
      item = this.createEmptyItem(uid, version);
    }
    item.replaceKinds(kindUidArray);

    return item;
  }

  /**
   * Задать признак обработки запросов для Видов на стороне сервисов
   * 
   * @prop {Boolean} pending значение признака обработки запросов для Видов на стороне сервисов @default false
   */
  @action
  setPendingKinds(pending = false) {
    this.pendingKinds = pending;
  }

  /**
   * Задать признак обработки запросов для Атрибутов на стороне сервисов
   * 
   * @prop {Boolean} pending значение признака обработки запросов для Атрибутов на стороне сервисов
   */
  @action
  setPendingAttributes(pending = false) {
    this.pendingAttributes = pending;
  }

  /**
   * Задать признак обработки запросов для представителей Вида на стороне сервисов
   * 
   * @prop {Boolean} pending значение признака обработки запросов для представителей Вида на стороне сервисов
   */
  @action
  setPendingItems(pending = false) {
    this.pendingItems = pending;
  }

  /**
   * Задать признак обработки запросов для участников Вида на стороне сервисов
   * 
   * @prop {Boolean} pending значение признака обработки запросов для участников Вида на стороне сервисов
   */
  @action
  setPendingMembers(pending = false) {
    this.pendingMembers = pending;
  }

  /**
   * Загрузить весь список Видов в системе АИС и список схем трассировки/gap анализа связей
   * 
   * @param {Bollean} withLocal получить список Видов из локального хранилища или от сервиса @default false
   * 
   * @returns {Promise}
   */
  @action
  async getKinds(withLocal = false) {
    this.setPendingKinds(true);
    this.setPendingAttributes(true);
    this.kinds.clear();

    try {
      const kinds = await this.api.getAllKinds(withLocal);
      if (kinds && kinds.length) {
        kinds.forEach((kindData) => {
          kindData.attributes &&
            kindData.attributes.forEach((attrData) => {
              this.createAttr(attrData);
            });
          this.createKind(kindData);
        });
      }
      await this.loadTraceSchemas();
    } finally {
      this.setPendingKinds(false);
      this.setPendingAttributes(false);
    }
  }

  /**
   * Загрузить данные атрибута
   * @param {String} uid uid атрибута
   * @param {Boolean} withLocal получить данные атрибута из локального хранилища или от сервиса @default false
   */
  @action
  async getAttrAsync(uid, withLocal = false) {
    const attrData = await this.api.getAttr(uid, withLocal);
    if (attrData) {
      this.createAttr(attrData);
    }
  }

  // @action
  // async setItem() {
  //   // this.setKindItem();
  //   // await this.getItem(uid);
  //   // this.setKindItem(uid);
  // }

  /**
   * Загрузить представителей Видов
   *
   * @param {Array<String>} uids набор uid объектов, для которых нужно получить участника Вида
   * @param {Number} version номер версии, для которой нужно получить представителей Вида @default 0
   * 
   * @returns {Array<KindItem>}
   */
  @action
  async getItems(uids = [], version = 0) {
    this.setPendingItems(true);
    try {
      const data = await this.getMembers(uids, version);
      const array = this.processMembers(data, version);
      return array;
    } finally {
      this.setPendingItems(false);
    }
    
    return [];
  }

  /**
   * Получить представителя Вида
   * 
  //  * @param {String} memberUid uid представителя Вида
   * @returns  {Array<KindItem>}
   */
  async getMemberItem(memberUid) {
    this.setPendingItems(true);
    try {
      const data = await this.api.getMemberItem(memberUid);
      const array = this.processMembers(data);
      return array;
    } finally {
      this.setPendingItems(false);
    }
    
    return [];
  }

  /**
   * Обработать полученные данные от сервиса при запросе списка участников Видов
   * 
   * @param {Array<Object>} data набор полученных данных
   * @param {Number} version номер версии, для которой нужно получить представителей Вида @default 0
   * 
   * @returns {Array<KindItem>}
   */
  @action
  processMembers(data, version = 0) {
    const array = [];
    if (data) {
      if (data) {
        if (data.length) {
          data.forEach((memberData) => {
            if (memberData) {
              array.push(this.createKindItem(memberData, version));
            }
          });
        } else {
          array.push(this.createKindItem(data, version));
        }
      }
    }
    return array;
  }

  /**
   * Сделать запрос к серсису для получения списка представителей Видов
   * 
   * @param {Array<String>} uids набор uid объектов, для которых нужно получить участника Вида
   * @param {Number} version номер версии, для которой нужно получить представителей Вида @default 0
   * 
   * @returns {Promise<Array<Object>>}
   */
  @action
  async getMembers(uids = [], version = 0) {
    const pairArray = uids.map((id) => {
      return { id, version };
    });
    return await this.api.getMembers(pairArray);
  }

  /**
   * Получить запись представителя Вида (KindItem)
   * Если такой записи нет, то создается пустая
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version номер версии @default 0
   * 
   * @returns {KindItem}
   */
  @action
  getItem(uid, version = 0) {
    let item = this.getItemSync(uid, version);
    if (item) {
      return item;
    } else {
      item = this.createEmptyItem(uid, version);
      this.getMember(uid, version); // TODO mock for data
      return item;
    }
  }

  /**
   * Получить синхронно запись представителя Вида (KindItem) из уже загруженных ранее представителей Вида
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version номер версии @default 0
   * 
   * @returns {KindItem}
   */
  @action
  getItemSync(uid, version = 0) {
    return this.kindItems.get(`${uid}-${version}`);
  }

  /**
   * Получить имена Видов, привязанных к представителю Вида (KindItem)
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version номер версии @default 0
   * 
   * @returns {Array<String>} имена Видов
   */
  @action
  getItemKindNames(uid, version = 0) {
    const item = this.getItemSync(uid, version);
    return item ? item.kindNames : [];
  }

  /**
   * Получить uid's Видов, привязанных к представителю Вида (KindItem)
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version номер версии @default 0
   * 
   * @returns {Array<String>} имена Видов
   */
  @action
  getItemKindUids(uid, version = 0) {
    const item = this.getItemSync(uid, version);
    return item ? item.kindUids : [];
  }

  /**
   * Изменить uid у представителя Вида (KindItem)
   * 
   * @param {String} id старый id
   * @param {String} newId  новый id
   * @param {Number} version номер версии @default 0
   * 
   * @returns {KindItem}
   */
  @action
  async changeItemId(id, newId, version = 0) {
    const item = this.getItemSync(id, version);
    item && item.setId(newId);
    this.kindItems.set(`${newId}-${version}`, item);
    this.kindItems.delete(`${id}-${version}`);
    const newItem = this.getItemSync(newId, version);
    newItem && await newItem.save();
    return newItem;
  }

  /**
   * Загрузить данные участника Вида
   * 
   * @param {String} uid uid объекта АИС
   * @param {Number} version номер версии @default 0
   * 
   * @returns {KindItem}
   */
  @action
  async getMember(uid, version = 0) {
    this.setPendingMembers(true);
    try {
      const item = this.createEmptyItem(uid, version);
      const data = await this.api.getMember(uid, version);
      this.processMembers(data, version);
      return item;
    } finally {
      this.setPendingMembers(false);
    }

    return null;
  }

  /**
   * Создать объект представителя Вида (KindItem)
   * 
   * @param {Object} data набор данных
   * @param {Number} version номер версии @default 0
   * 
   * @returns {KindItem}
   */
  @action
  createKindItem(data, version = 0) {
    if (!data || !data.objectId) {
      return null;
    }
    let item = this.objectStore.getVersion(data.objectId, DOMAIN_KINDS, version);
    if (!item) {
      item = new KindItem({ ...data, version }, this);
      this.objectStore.addVersion(item);
    } else {
      item.addMemberData(data);
    }
    this.kindItems.set(`${item.uid}-${version}`, item);
    return item;
  }

  /**
   * Добавить участника Вида в общий список всех участников Вида
   * 
   * @param {KindMember} kindMember объект участника Вида
   */
  @action
  addKindMember(kindMember) {
    this.kindMembers.set(kindMember.uid, kindMember);
  }

  /**
   * Получить синхронно участника Вида из общего списка уже загруженных ранее всех участников Вида
   * 
   * @param {String} memberUid uid участника Вида
   * 
   * @returns {KindMember} участника Вида
   */
  @action
  getKindMemberSync(memberUid) {
    return this.kindMembers.get(memberUid);
  }

  /**
   * Создать пустой объект представителя Вида {KindItem}
   * 
   * @param {String} uid объекта АИС
   * @param {Number} version версия объекта @default 0
   * 
   * @returns {KindItem}  пустой объект представителя Вида
   */
  @action
  createEmptyItem(uid, version = 0) {
    if (uid) {
      const item = new KindItem(
        { objectId: uid, version },
        this
      );
      this.objectStore.addVersion(item);
      this.kindItems.set(`${item.uid}-${version}`, item);
      return item;
    }
  }

  /**
   * Создать Атрибут
   * 
   * @param {Object} data данные атрибута
   * 
   * @returns {Attr} объект атрибута
   */
  @action
  createAttr(data) {
    const attr = new Attr(data, this);
    this.attrs.set(attr.id, attr);
    this.attrsByName.set(attr.name, attr);
  }

  // @action
  // async getKindItems(kindId) {
  //   const items = await this.api.getKindMembers(kindId);
  //   const thisKindItems = [];
  //   if (items) {
  //     items.forEach((itemData) => {
  //       const { uid, etype } = itemData.object;
  //       let item = this.kindItems.get(uid);
  //       if (item) {
  //         item.addMemberData(itemData);
  //       } else {
  //         const data = {
  //           uid,
  //           name:  uid,
  //           etype,
  //           kinds: {
  //             [kindId]: {
  //               member: itemData.uid,
  //               values: itemData.values
  //             }
  //           }
  //         };
  //         item = this.createKindItem(data);
  //       }
  //       thisKindItems.push(item);
  //     });
  //   }
  //   this.itemsByKindMap.set(kindId, thisKindItems);
  // }

  // @action
  // setKindItem() {
  //   // this.selectedKindItem = uid;
  // }

  // @action
  // async createProject() {
  //   const data = await this.api.createKind({
  //     name:       "Проект",
  //     attributes: [
  //       {
  //         name: "Индекс",
  //         type: "string"
  //       },
  //       {
  //         name: "Название проекта",
  //         type: "string"
  //       },
  //       {
  //         name: "Описание",
  //         type: "string"
  //       }
  //     ]
  //   });
  //   this.createKind(data);
  // }

  /**
   * Получить объект Вида
   * 
   * @param {String} uid uid Вида
   * 
   * @returns {Kind}
   */
  getKind(uid) {
    return this.kinds.get(uid);
  }

  /**
   * Получить объект Атрибута
   * 
   * @param {String} uid uid Атрибута
   * 
   * @returns {Attr}
   */
  getAttr(uid) {
    return this.attrs.get(uid);
  }

  // @computed
  // get currentItem() {
  //   return this.kindItems.get(this.selectedKindItem);
  // }

  /**
   * Признак обработки/загрузки Видов и их участников на стороне сервиса
   * 
   * @returns {Boolean}
   */
  @computed
  get isPendingData() {
    return this.pendingItems || this.pendingMembers;
  }

  /**
   * Признак обработки/загрузки Атрибутов на стороне сервиса
   * 
   * @returns {Boolean}
   */
  @computed
  get isPendingAttributes() {
    return this.pendingAttributes;
  }

  /**
   * Признак загрузки списка Видов
   * 
   * @returns {Boolean}
   */
  @computed
  get isPendingKinds() {
    return this.pendingKinds;
  }

  /**
   * Признак обработки/загрузки представителей Видов {KindItem} на стороне сервиса
   * 
   * @returns {Boolean}
   */
  @computed
  get isItemsPending() {
    let pending = false;
    this.kindItems.forEach((item) => {
      if (item && item.isPending) {
        pending = true;
      }
    });
    return pending;
  }

  /**
   * Признак обработки/загрузки Атрибутов ил Видов на стороне сервиса
   * 
   * @returns {Boolean}
   */
  @computed
  get isPending() {
    return this.isPendingAttributes || this.isPendingKinds;
  }

  /**
   * Получить список Option для выпадающего списка с доступными Видами
   * 
   * @returns {Array<Option>}
   */
  @computed
  get kindsForSelect() {
    const kindsArray = [];
    this.kinds.forEach((kind) => {
      kindsArray.push({
        value: kind.id,
        label: kind.name,
        icon:  this.rootStore.accountStore.getIcon(kind.uid)
      });
    });
    return kindsArray;
  }

  /**
   * Загрузить набор схем трассировок для Видов
   * 
   * @returns {Promise}
   */
  async loadTraceSchemas() {
    const schemasData = await this.api.loadTraceSchemas() || [];
    this.kinds.forEach((kind) => {
      kind.clearTraceSchemas();
    });

    schemasData.forEach((data) => {
      const schema = TraceSchema.create(data, this.rootStore);
      const kind = this.kinds.get(schema.applicableToKind);
      if (kind) {
        kind.addTraceSchema(schema);
      }
    });
  }
}
export default KindsStore;
