import { observable, computed, action } from "mobx";
import {
  IssueModel,
  ProjectModel as RedmineProjectModel
} from "../models";

import { Relation } from "~/modules/relations/models";
import RelationStore from "~/modules/relations/stores/relationStore";
import IssueApi from "../api/issueApi";
import processComposerAisObject from "~/core/utils/aisHelpers";
import AttachedIssueModel from "../models/attachedIssueModel";

import { MODULE_NAME_CFG } from "../constants/config";
import { CLS_ISSUE } from "../../../core/constants/Classes";

/**
 * Хранилище для работы с задачами, прикрепленных к артефакту
 *
 * @class AttachedIssueStore
 */
class AttachedIssueStore {
  /**
   * Cписок задач
   *
   * @type {Map <AttachedIssueModel>}
   */
  @observable
  attachedIssuesMap = new Map(); 

  /**
   * Cписок найденных задач
   *
   * @type {Map <IssueModel>}
   */
  @observable
  foundedIssues = [];

  /**
   * Cписок доступных проектов из Redmine
   *
   * @type {Map <RedmineProjectModel>}
   */
  @observable
  projectsMap = new Map();


  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @type {Boolean}
   */
  @observable
  processing = false;

  /**
   * Флаг, указывающий, что идет инициалиация хранилища - загруза небходимых данных
   *
   * @type {Boolean}
   */
  @observable
  isInitialization = false;

  /**
   * uid текущего артефакта АИС, к которому привязаны задачи
   *
   * @type {String}
   */
  @observable
  currentAisObjectUid = undefined;

  /**
   * uid текущего проекта
   *
   * @type {String}
   */
  @observable
  currentProjectUid = undefined;


  /**
   * Режим только чтение
   *
   * @type {Boolean}
   */
  @observable
  readOnly = true;

  /**
   * Кол-во записей на странице в табличном представлением
   *
   * @type {Number}
   */
  @observable
  pageSize = 20;

  /**
   * Номер текущей страницы в табличном представлением
   *
   * @type {Number}
   */
  @observable
  currentPage = 1;

  /**
   * Общее кол-во записей
   *
   * @type {Number}
   */
  @observable
  totalIssues = 0;

  
  constructor({ rootStore }) {
    this.rootStore = rootStore;
    this.api = new IssueApi(rootStore);
    this.relationStore = new RelationStore(rootStore);
  }

  /**
   * Список задач
   *
   * @return {Array<AttachedIssueModel>}
   */
  @computed
  get attachedIssueList() {
    return Array.from(this.attachedIssuesMap.values());
  }

  /**
   * Список сгруппированных задач по типам (трекерам)
   *
   * @return {Array<AttachedIssueModel>}
   */
  @computed
  get groupedAttachedIssues() {
    const grouped = {};
    if (this.attachedIssueList.length === 0) {
      return grouped;
    }
    this.attachedIssueList.forEach((attachedIssue) => {
      const { issue } = attachedIssue;
      if (issue) {
        // группируем по названию трекера
        const { tracker } = issue;
        if (!grouped[tracker.name]) {
          grouped[tracker.name] = [];
        }
        grouped[tracker.name].push(attachedIssue);
      }
    });

    return grouped;
  }

  /**
   * Список проектов Redmine
   *
   * @return {Array<RedmineProjectModel>}
   */
  /**
   * Добавить задачу в список под ее uid
   *
   * @param {AttachedIssueModel} issue модель задачи
   */
  @action
  addAttachedIssue(attachedIssue) {
    this.attachedIssuesMap.set(attachedIssue.uid, attachedIssue);
  }

  /**
   * Удалить задачу из списка по ее uid
   *
   * @param {String} uid задачи
   */
  @action
  removeAttachedIssue(uid) {
    this.attachedIssuesMap.delete(uid);
  }

  /**
   * Добавить проект в Redmine в список под его id
   *
   * @param {RedmineProjectModel} project модель проекта в Redmine
   */
  @action
  addProject(project) {
    this.projectsMap.set(project.uid, project);
  }

  /**
   * Изменение значения флага, указывающий, что идет обработка/загрузка данных
   *
   * @param {Boolean} value значение
   */
  @action
  setIsProcessing(value) {
    this.processing = value;
  }

  /**
   * Изменение значения флага, указывающий, что идет инициализация хранилища
   *
   * @param {Boolean} value значение
   */
  @action
  setIsInitialization(value) {
    this.isInitialization = value;
  } 

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @return {Boolean} 
   */
  @computed
  get isProcessing() {
    return this.processing  || this.isInitialization;
  }
  
  /**
   * Устанвоить uid текущего артефакта АИС, к котрому прикреплены задачи
   *
   * @param {String} value значение
   */
  @action
  setCurrentAisObjectUid(value) {
    this.currentAisObjectUid = value;
  }

  /**
   * Устанвоить uid текущего проекта
   *
   * @param {String} value значение
   */
  @action
  setCurrentProjectUid(value) {
    this.currentProjectUid = value;
  }

  /**
   * Выставить значение readOnly
   *
   * @param {Boolean} value
   */
  @action
  updateReadOnly(value) {
    this.readOnly = value;
  }

  /**
   * Задать кол-во записей на странице в табличном представлении
   *
   * @param {Number} size кол-во записей
   */
  @action
  setPageSize(size) {
    this.pageSize = size || 20;
    this.setCurrentPage(1);
  }

  /**
   * Задать номер текущей страницы в табличном представлении
   *
   * @param {Number} page номер текущей страницы в табличном представлении
   */
  @action
  setCurrentPage(page) {
    this.currentPage = page || 1;
  }

  /**
   * Задать общее кол-во записей
   *
   * @param {Number} value общее кол-во записей
   */
  @action
  setTotalIssues(value = 0) {
    this.totalIssues = value;
  }

  /**
   * Кол-во страниц
   *
   * @return {Number}
   */

  @computed
  get pages() {
    if (this.pageSize && this.totalIssues) {
      return Math.ceil(this.totalIssues / this.pageSize);
    }

    return -1;
  }

  /**
   * Можно ли перейти на предыдущую страницу
   *
   * @return {Boolean}
   */
  @computed
  get canPreviousPage() {
    return this.currentPage > 1;
  }

  /**
   * Можно ли перейти на следуюущую страницу
   *
   * @return {Number}
   */
  @computed
  get canNextPage() {
    return this.currentPage < this.pages;
  }

  /**
   * Получить задачу по ее uid
   *
   * @param {String} uid задачи
   *
   * @return {IssueModel}
   */
  getAttahcedIssue(uid) {
    return this.attachedIssuesMap.get(uid);
  }

  @computed
  get projectList() {
    return Array.from(this.projectsMap.values());
  }

  /**
   * Список проектов  в локальном Redmine
   *
   * @return {Array<RedmineProjectModel>}
   */
  @computed
  get localProjectList() {
    return this.projectList.filter((pr) => {
      return !pr.isExternal;
    });
  }

  /**
   * Получить проект Redmine по его id
   *
   * @param {String} id проекта в Redmine
   *
   * @return {RedmineProjectModel}
   */
  getProject(id) {
    return this.projectsMap.get(id);
  }

  /**
   * Получить проект Redmine по его uid
   *
   * @param {String} uid проекта в АИС
   *
   * @return {RedmineProjectModel}
   */
  getProjectByUid(uid) {
    return Array.from(this.projectsMap.values()).filter((pr) => {
      return pr.uid === uid;
    })[0];
  }

  /**
   * Метод для инициализации хранилища.
   * Необходимо вывать для загузки словарей данны - статусы задач, приоритеты задач и т.п.
   */
  async init() {
    this.setIsInitialization(true);
    try {
      await this.loadProjects();
    } finally {
      this.setIsInitialization(false);
    }
  }

  /**
   * Загрузить список проектов Redmine
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadProjects() {
    try {
      const data = await this.api.loadRedmineProjects();
      (data.linked || []).forEach((item) => {
        this.addProject(RedmineProjectModel.create(item, this));
      });
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список задач для проекта
   *
   * @params {String} aisObjectUid uid артефакта АИС, к которому привязаны задачи
   * @params {Number} aisObjectVersion номер версии артефакта АИС, к которому привязаны задачи
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadAttachedIssues(aisObjectUid, aisObjectVersion = 0) {
    this.setIsProcessing(true);
    try {
      this.clearAttachedIssues();
      this.setCurrentAisObjectUid(aisObjectUid);

      // Composer не может вернуть по фильтру только прирепленные задачи
      // Поэтому загружаем все прикерпленные задачи и далее их фильтруем
      const objects = await this.api.getAttachedObjects({ id: aisObjectUid, version: aisObjectVersion });
      const currentObject = Array.isArray(objects) && objects[0];
      
      if (!currentObject) {
        return;
      }

      const { links, uid, version } = currentObject;
      if (Array.isArray(links)) {
        await await Promise.all(links.map((link) => {
          return this.processLink({ aisObjectUid: uid, aisObjectVersion: version, link });
        }));
      }
      return this.issueList;
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }

  /**
   * Прикрепить найденную задачу к АИС обхекту
   * 
   * @param {Object} issue данные Задачи
   * @param {Object} target данные АИС объекта (uid, версия, class), к которому будеть прикрепляться найденная Задача
   * @param {String} relationKindUid uid Вида связи
   * @param {String} direction направление связи from|to
   * 
   * @returns {Boolean} успешность добавления - true|false
   */
  async linkAttachedIssue(issue, target, relationKindUid, direction) {
    let res =  false;
    this.setIsProcessing(true);
    try {
      if (direction === "from") {          
        await this.relationStore.connect(issue, target, relationKindUid);
      } 
      if (direction === "to") {
        await this.relationStore.connect(target, issue, relationKindUid);
      }
      res = true;
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return res;
  }

  /**
   * Открепить задачу
   * 
   * @param {AttachedIssueModel} attachedIssue запись прикрепленной задачи
   * 
   * @returns {Boolean} успешность удаления - true|false
   */
  async unlinkAttachedIssue(attachedIssue) {
    let res =  false;
    this.setIsProcessing(true);
    try {
      const { uid, relation } =  attachedIssue;
      await this.relationStore.api.deleteRelation(relation.id);
      this.removeAttachedIssue(uid);
      res = true;
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return res;
  }

  /**
   * Обработка данных с записями связей, полученных от Composer
   * 
   * @param {Object} params  набор параметров 
   * @param {String} params.aisObjectUid  uid АИС объекта, к которому приходят записи  
   * @param {Number} params.aisObjectVersion  номер версии АИС объекта, к которому приходят записи 
   * @param {Object} params.link  данные записи, полученный от Composer
   */
  async processLink({ aisObjectUid, aisObjectVersion, link }) {
    const { dest, source, uid, type:linkType, class:klass } = link;
    let direction;
    let issue;
    // Фильтруем только задачи
    if (source && source.representation && source.class === CLS_ISSUE && source.uid !== aisObjectUid) {
      direction = "source";
      issue = await processComposerAisObject(source, this.rootStore.objectStore);
    }
    if (dest && dest.representation && dest.class === CLS_ISSUE && dest.uid !== aisObjectUid) {
      direction = "dest";
      issue = await processComposerAisObject(dest, this.rootStore.objectStore);
    }
    if (direction && issue) {
      const relation = Relation.create({ uid, class: klass, source, dest, linkType }, this.relationStore);
      // const relation = Relation.create({ uid, class: klass, source, dest, linkType });
      this.addAttachedIssue(
        AttachedIssueModel.create({ aisObjectUid, aisObjectVersion, direction, relation, issue }, this)
      );
    }
  }

  /**
   * Найти задачи по тексту
   *
   * @param {String} text текст поиска
   * @param {String} projectUid uid проекта, в котром нужно искать задачи. Если параметр не задан, 
   * то поиск будет просизодить по всем проектам
   * 
   * @returns {Array<IssueModel>}
   */
  async searchIssuesByText(text, projectUid) {
    try {
      const issues = await this.api.searchIssuesByText(text, projectUid, 100);
      this.foundedIssues = issues.map((issue) => {
        return {
          ...issue,
          title:       issue.subject,
          titlePrefix: IssueModel.titlePrefix(issue.tracker, issue.id),
          iconString:  this.getIconByTracker(issue.tracker),
          className:   CLS_ISSUE
        };
        // return IssueModel.create(issue, this);
      });
    } catch (ex) {
      this.onError(ex);
    }
    return [];
  }

  /**
   * Загрузить задачу по ее uid
   *
   * @param {String} uid задачи
   * @return {IssueModel}
   */
  async loadIssue(issueUid) {
    try {
      const data = await this.api.loadIssue(issueUid);
      await this.rootStore.kindsStore.getItems([issueUid]);
      let issue = this.getIssue(issueUid);
      if (issue) {
        issue.update(data);
      } else {
        issue =  IssueModel.create(data, this, this);
      }

      // Загружаем информацию о прикрепленных файлах в attachments
      await Promise.all(issue.attachmentList.map(async(attachment) => {
        const meta = await this.loadFileMetadata(attachment.aisId);
        meta && attachment.setMetadata(meta);
      }));

      // Загружаем информацию о прикрепленных фсайлах в пользовательских полях
      await Promise.all(issue.customAttachmentList.map(async(attachment) => {
        const meta = await this.loadFileMetadata(attachment.aisId);
        meta && attachment.setMetadata(meta);
      }));
      return issue;
    } catch (e) {
      this.onError(e);
    }

    return undefined;
  }

  
  /**
   * Очистить список задач
   *
   */
  @action
  clearAttachedIssues() {
    this.attachedIssuesMap.clear();
  }

  /**
   * Очистить словарь данных - список проектов
   *
   */
  @action
  clearDictonaryData() {
    this.projetsMap.clear();
  }

  /**
   * Очистить найденные задачи
   *
   */
  @action
  clearFoundedIssues() {
    this.foundedIssues.clear();
  }

  /**
   * Очистить данные
   *
   */
  @action
  clear() {
    this.setCurrentProjectUid(undefined);
    this.clearAttachedIssues();
    this.clearFoundedIssues();
    this.clearDictonaryData();
  }

  /**
   * Получить конфигурацию инструмент", onа;
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return (this.config && this.config[name]) || {};
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }

  getIconByTracker(tracker) {
    if (this.rootStore && tracker) {
      const kind = this.rootStore.kindsStore.getKind(tracker.uid);
      if (kind) {
        return this.rootStore.accountStore.getIcon(kind.uid);
      }
    }

    return "reglament-M";
  }

  
  /**
   * Функция для вызова сообщения обшибке
   *
   * @param {String} msg текст сообщения об ошибке
   */
  onError(msg) {
    this.rootStore.onError(msg);
  }


  /**
   * Метод разрушения хранилища
   *
   */
  destroy() {
    this.clear();
  }
}

export default AttachedIssueStore;
