import Server from "~/core/api/server";


class IssueApi extends Server {
  methods = {
    loadIssues: {
      // Загрузить список задач для проекта
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/issues`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка задач...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`,
      timeout:     300000

    },
    loadIssuesByFilter: {
      // Загрузить список задач с применеием фильтров
      url:         this.getServiceUrl("tasks", "api/v1/issues/filter"),
      method:      "POST",
      textProcess: "Загрузка списка задач...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadUidsIssueByFilter: {
      // Загрузить список uid задач с применеием фильтров
      url:         this.getServiceUrl("tasks", "api/v1/issues/filter/uids"),
      method:      "POST",
      textProcess: "Загрузка списка uid задач...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка uid задач произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadIssue: {
      // Загрузить данные задачи
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка данных задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    createIssue: {
      // Создать задачу
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/issues`, params);
      },
      method:      "POST",
      textProcess: "Создание задачи...",
      textSuccess: null,
      textError:   this.template`Во время создания задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    saveIssue: {
      // сохранить задачу
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "PATCH",
      textProcess: "Сохранение данных задачи ...",
      textSuccess: null,
      textError:   this.template`Во время сохранения данных задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    changeStatusIssue: {
      // обновить статус у задачи
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "PATCH",
      textProcess: "Изменение статуса у задачи ...",
      textSuccess: null,
      textError:   this.template`Во время изменения статуса у задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    saveBulkIssues: {
      // сохранить несколько задач сразу
      url:         this.getServiceUrl("tasks", "api/v1/issues/bulk"),
      method:      "PATCH",
      textProcess: "Изменение данных у нескольких задач ...",
      textSuccess: null,
      textError:   this.template`Во время изменения данных у нескольких задач произошла ошибка: ${0}`,
      timeout:     30000
    },
    loadStatuses: {
      // Загрузить список статусов задачи
      url:         this.getServiceUrl("tasks", "api/v1/issues/statuses"),
      method:      "GET",
      textProcess: "Загрузка списка статусов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка статусов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadStatusesFromInstance: {
      // Загрузить список статусов задачи c внешнего источника
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v2/instances/${0}/issues/statuses`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка статусов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка статусов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadPriorities: {
      // Загрузить список приоритетов задачи
      url:         this.getServiceUrl("tasks", "api/v1/issues/priorities"),
      method:      "GET",
      textProcess: "Загрузка списка приоритетов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка приоритетов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadPrioritiesFromInstance: {
      // Загрузить список приоритетов задачи c внешнего источника
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v2/instances/${0}/issues/priorities`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка приоритетов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка приоритетов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadTrackers: {
      // Загрузить список трекеров
      url:         this.getServiceUrl("tasks", "api/v1/trackers"),
      method:      "GET",
      textProcess: "Загрузка списка трекеров задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка трекеров задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadTrackersFromInstance: {
      // Загрузить список трекеров c внешнего источника
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v2/instances/${0}/trackers`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка трекеров задачи c внешнего источника...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка трекеров c внешнего источника задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadUsers: {
      // Загрузить список пользователей
      url:         this.getServiceUrl("tasks", "api/v1/users"),
      method:      "GET",
      textProcess: "Загрузка списка пользователей...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка пользователей произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadUsersFromInstance: {
      // Загрузить список пользователей c внешнего источника
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v2/instances/${0}/users`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка пользователей...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка пользователей произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadRedmineProjects: {
      // Загрузить список проектов в Redmine
      url:         this.getServiceUrl("tasks", "api/v1/projects"),
      method:      "GET",
      textProcess: "Загрузка списка проектов в сервисе `Задачи` ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка проектов в сервисе "Задачи" произошла ошибка: ${0}`,
      timeout:     300000
    },
    checkProjectTrackers: {
      // Проверки связанных трекеров с текущим проектом T5543
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/trackers`, params);
      },
      method:      "POST",
      textProcess: "Проверка связанных трекеров с текущим проектом ...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время проверки связанных трекеров с текущим проектом  произошла ошибка: ${0}`
    },
    checkProjectsLinks: {
      // Получение списка связанных проектов Redmine с набором переданных uid'ов
      url:         this.getServiceUrl("tasks", "api/v1/projects/links/bulk"),
      method:      "POST",
      textProcess: "Проверка связанных проектов в сервисе `Задачи`...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время проверки связанных проектов сервисе "Задачи" произошла ошибка: ${0}`
    },
    createRedmineProject: {
      // Создание нового проекта в Redmine
      url:         this.getServiceUrl("tasks", "api/v1/projects"),
      method:      "POST",
      textProcess: "Создание нового проекта...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время создания нового проекта произошла ошибка: ${0}`
    },
    file: {
      // Получение файла c хранилища АИС
      url: (params) => {
        return this.getServiceUrl("files", this.template`api/v1/files/${0}`, params);
      },
      method:      "GET",
      textProcess: "Получение файла из хранилища...",
      textSuccess: null,
      textError:   this.template`Во время получения файла из хранилища произошла ошибка: ${0}`,
      timeout:     20000
    },
    uploadFile: {
      // Загрузка файлов
      url:         this.getServiceUrl("files", "api/v1/files"),
      method:      "POST",
      textProcess: "Загрузка файла в хранилище...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время загрузки файла в хранилище произошла ошибка: ${0}`
    },
    loadFileMetadata: {
      // Получение данных о файле c хранилища АИС
      url: (params) => {
        return this.getServiceUrl("files", this.template`api/v1/files/${0}/metadata`, params);
      },
      method:      "GET",
      textProcess: "Получение данных о файле c хранилища АИС...",
      textSuccess: null,
      textError:   this.template`Во время получения данных о файле c хранилища АИС произошла ошибка: ${0}`,
      timeout:     20000
    },
    searchIssuesByText: {
      // Поиск задач по переданному тексту
      url:         this.getServiceUrl("tasks", "api/v1/issues/typing"),
      method:      "POST",
      textProcess: "Поиск задач...",
      textSuccess: null,
      textError:   this.template`Во время поиска задач произошла ошибка: ${0}`,
      timeout:     20000
    },
    loadCoordLetterTemplates: {
      // Загрузить список шаблонов КП 
      url:         this.getServiceUrl("tasks", "api/v1/templates"),
      method:      "GET",
      textProcess: "Загрузка списка шаблонов КП ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка шаблонов КП произошла ошибка: ${0}`
    },
    loadCoordLetterTemplate: {
      // Загрузить шаблон КП по его uid
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/templates/${0}`, params);
      },
      
      method:      "GET",
      textProcess: "Загрузка шаблона КП ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки шаблона КП произошла ошибка: ${0}`
    },
    getProjectsLibrayNodes: {
      // Загрузить ноды Библиотеки
      // url:         this.getServiceUrl("composer", ""),
      // url:         this.getServiceUrl("library", "api/v2/found-nodes"),
      url:         this.getServiceUrl("kinds", "api/v2/members/found-members"),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Поиск нод Библиотеки ...",
      textSuccess: null,
      textError:   this.template`Во время поиска нод Библиотеки произошла ошибка: ${0}`,
      timeout:     600000
    },
    getCoordLetterTemplatesNames: {
      // Загрузить имена РМ шаблонов КП
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Поиск названий шаблонов ...",
      textSuccess: null,
      textError:   this.template`Во время поиска названий шаблонов произошла ошибка: ${0}`,
      timeout:     600000
    },
    createCoordLetter: {
      // Создать Координационное письмо
      url:         this.getServiceUrl("tasks", "api/v1/memo"),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Создание Координационного письма ...",
      textSuccess: null,
      textError:   this.template`Во время создания Координационного письма произошла ошибка: ${0}`,
      timeout:     600000
    },
    exportToPdf: {
      // Экспорт задачи в PDF
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}/export`, params);
      },
      method:         "GET",
      accept:         "application/pdf",
      responseIsBlob: true,
      textProcess:    "Экспорт задачи в PDF...",
      textSuccess:    null,
      textError:      this.template`Во время экспорта задачи в PDF произошла ошибка: ${0}`,
      timeout:        60000
    },
    exportToDocx: {
      // Экспорт задачи в DOCX
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}/export`, params);
      },
      method:         "GET",
      responseIsBlob: true,
      accept:         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      textProcess:    "Экспорт задачи в DOCX...",
      textSuccess:    null,
      textError:      this.template`Во время экспорта задачи в DOCX произошла ошибка: ${0}`,
      timeout:        60000
    },
    getAttachedObjects: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      textProcess: "Получение прикрепленных задач...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время получения прикрепленных задач произошла ошибка: ${0}`
    }
  };


  /**
   * Загрузить список задач
   * @param {String} uid проекта
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   */
  async loadIssues(uid, page, perPage) {
    const reqData = {
      params: [uid]
    };

    if (page && perPage) {
      reqData.params.push(page, perPage);
    }

    return await this.request("loadIssues", reqData);
  }

  /**
   * Загрузить список задач использую набор фильтров
   *
   * @params {Object} filter набор фильтров
   * @params {Array<String>} filter.project - список uid'ов проектов
   * @params {Array<String>} filter.tracker - список uid'ов трекеров
   * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
   * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
   * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
   * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
   * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
   * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
   * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
   * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
   * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
   * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
   * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
   * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
   * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
   * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
   *                  (при отсутствии или null поиск является нестрогим)
   *
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   * 
   * @return {Promise}
   */
  async loadIssuesByFilter(filters, page, perPage) {
    return await this.request("loadIssuesByFilter", {
      ...filters,
      page,
      perPage
    });
  }

  /**
   * Загрузить список uid задач использую набор фильтров
   *
   * @params {Object} filter набор фильтров
   * @params {Array<String>} filter.project - список uid'ов проектов
   * @params {Array<String>} filter.tracker - список uid'ов трекеров
   * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
   * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
   * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
   * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
   * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
   * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
   * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
   * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
   * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
   * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
   * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
   * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
   * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
   * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
   *                  (при отсутствии или null поиск является нестрогим)
   *
   * @return {Promise}
   */
  async loadUidsIssueByFilter(filters) {
    return await this.request("loadUidsIssueByFilter", filters);
  }

  /**
   * Загрузить данные задачи
   * @param  {String} uid задачи
   *
   */
  async loadIssue(uid) {
    const reqData = {
      params: [uid]
    };

    return await this.request("loadIssue", reqData);
  }

  /**
   * Создание задачи
   *
   * @params {Object} data данные задачи
   * @params {String} data.projectUid uid проекта в АИС
   *
   * @return {Promise}
   */
  async createIssue(data) {
    const reqData = {
      params: [data.projectUid],
      data
    };
    return await this.request("createIssue", reqData);
  }

  /**
   * Сохранить данные у задачи
   *
   * @params {String} issueUid uid задачи
   * @params {Object} data данные для сохранения
   *
   * @return {Promise}
   */
  async saveIssue(issueUid, data) {
    const reqData = {
      params: [issueUid],
      data
    };
    return await this.request("saveIssue", reqData);
  }

  /**
   * Изменить статус у задачи
   *
   * @params {String} issueUid uid задачи
   * @params {String} statusId id статуса
   *
   * @return {Promise}
   */
  async changeStatusIssue(issueUid, statusId) {
    const reqData = {
      params: [issueUid],
      data:   {
        statusId
      }
    };
    return await this.request("changeStatusIssue", reqData);
  }

  /**
   * Изменить статус у задачи
   *
   * @params {Array <Object>} data списо данных для сохранения списка задач
   *
   * @return {Promise}
   */
  async saveBulkIssues(data) {
    return await this.request("saveBulkIssues", data);
  }

  /**
   * Загрузить список статусов задачи
   *
   * @param {String} uid внешнего источника
   * Если проект из внешнего ресурса, то нужно для него загрузить набор статусов из внешнего ресурса
   */
  async loadStatuses(instanceUid) {
    if (instanceUid) {
      const reqData = {
        params: [instanceUid]
      };
      return await this.request("loadStatusesFromInstance", reqData);  
    }
    return await this.request("loadStatuses");
  }

  /**
   * Загрузить список приоритетов задачи
   *
   * @param {String} uid внешнего источника
   * Если проект из внешнего ресурса, то нужно для него загрузить набор приоритетов из внешнего ресурса
   */
  async loadPriorities(instanceUid) {
    if (instanceUid) {
      const reqData = {
        params: [instanceUid]
      };
      return await this.request("loadPrioritiesFromInstance", reqData);  
    }
    return await this.request("loadPriorities");
  }

  /**
   * Загрузить список трекеров задачи
   *
   * @param {String} uid внешнего источника
   * Если проект из внешнего ресурса, то нужно для него загрузить набор трекеров из внешнего ресурса
   */
  async loadTrackers(instanceUid) {
    if (instanceUid) {
      const reqData = {
        params: [instanceUid]
      };
      return await this.request("loadTrackersFromInstance", reqData);  
    }
    return await this.request("loadTrackers");
  }

  /**
   * Загрузить список пользователей
   *
   * @param {String} uid внешнего источника
   * Если проект из внешнего ресурса, то нужно для него загрузить набор пользователей из внешнего ресурса
   */
  async loadUsers(instanceUid) {
    if (instanceUid) {
      const reqData = {
        params: [instanceUid]
      };
      return await this.request("loadUsersFromInstance", reqData);  
    }
    return await this.request("loadUsers");
  }

  /**
   * Загрузить список проектов в Redmine
   *
   */
  async loadRedmineProjects() {
    return await this.request("loadRedmineProjects");
  }

  /**
   * Проверить набор переданных uid'ов на связь с проектами в Redmine
   *
   * @param {Array<String>} массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectsLinks(uids = []) {
    return await this.request("checkProjectsLinks", uids);
  }

  /**
   * Проверить связанные трекеры с текущим проектом по набору uid Видов или id трекеров Redmine 
   *
   * @param {String} projectUid uid текущего проекта в АИС
   * @param {Array<String>} uids массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine 
   * @param {Array<String>} ids массив id'ов трекеров Redmine, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectTrackers(projectUid, uids = [], ids = []) {
    const reqData = {
      params: [projectUid],
      data:   {
        uids,
        ids
      }
    };
    return await this.request("checkProjectTrackers", reqData);
  }


  /**
   * Создать новый проект в Redmine
   *
   * @param {String} uid проекта в АИС
   * @param {String} Название проекта
   */
  async createRedmineProject(uid, name) {
    const reqData = {
      uid,
      name
    };
    return await this.request("createRedmineProject", reqData);
  }

  /**
   * Загрузить данные файла с хранилища АИС
   *
   * @param  {String} id файла в хранилище
   * @return {Promise}
   */
  async loadFileMetadata(id) {
    const reqData = {
      params: [id]
    };
    return await this.request("loadFileMetadata", reqData);
  }

  /**
   * Загрузить файл с хранилища АИС
   *
   * @param  {String} id файла в хранилище
   * @return {Promise}
   */
  async downloadFile(id) {
    const reqData = {
      params: [id]
    };
    return await this.request("file", reqData);
  }

  /**
   * Выгрузить файл в хранилище АИС
   *
   * @param  {File} file
   * @return {Promise}
   */
  async uploadFile(file) {
    const reqData = {
      user_uid: this.rootStore.accountStore.uid,
      file:     [file]
    };

    return await this.request("uploadFile", reqData, true);
  }

  async searchIssuesByText(text, projectUid, maxCount = 10) {
    const reqData = {
      searchValue: text,
      projectUid,
      maxCount
    };

    return await this.request("searchIssuesByText", reqData);
  }

  /**
   * Загрузить список шаблонов КП
   *
   * @return {Promise}
   */
  async loadCoordLetterTemplates() {
    return await this.request("loadCoordLetterTemplates");
  }

  /**
   * Загрузить шаблон КП
   *
   * @return {Promise}
   */
  async loadCoordLetterTemplate(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("loadCoordLetterTemplate", reqData);
  }

  /**
   * Получить ноды Библиотеки зная только их uid'ы
   * 
   * @param {Array<Object>} массив объектов - uid и весрия текстового представления
   * @returns {Promise}
   */
  async loadProjectsLibraryNodes1(uids = []) {
    const reqData = {
      operationName: "Objects",
      query:         `query Objects {
      Objects(ids_and_versions: [${uids.map((uid) => {
    return `{id: "${uid}", version:0}`;
  })}]) {
    ... on AisObject {
      uid
      class
    	title
      version
      representation
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    ... on Object {
      id
      class
      version
    }
  }
}` 
    };
    const data = await this.request("getProjectsLibrayNodes", reqData);
    return data.Objects;
  }

  /**
   * Получить ноды Библиотеки зная только их uid'ы
   * 
   * @param {Array<Object>} массив объектов - uid и весрия текстового представления
   * @returns {Promise}
   */
  async loadProjectsLibraryNodes(uids = []) {
    const reqData = uids.map((id) => {
      return { id, version: 0 };
    });
    const data = await this.request("getProjectsLibrayNodes", reqData);
    return data;
  }


  /**
   * Получить назание нод Библиотеки зная только uid ноды текcтового представления и версию
   * 
   * @param {Array<Object>} массив объектов - uid и весрия текстового представления
   * @returns {Promise}
   */
  async getCoordLetterTemplatesName(uids) {
    const reqData = {
      operationName: "Objects",
      query:         `query Objects {
      Objects(ids_and_versions: [${uids.map((item) => {
    return `{id: "${item.uid}", version: ${item.version}}`;
  })}]) {
    ... on AisObject {
      uid
      title
      version
    }
  }
}` 
    };
    const data = await this.request("getCoordLetterTemplatesNames", reqData);
    return data.Objects;
  }

  /**
   * Создать Координационное письмо
   * 
   * @param {Object} набор параметров для создания Координационного письма
   * @returns {Promise}
   */
  async createCoordLetter(data) {
    return await this.request("createCoordLetter", data);    
  }

  /**
   * Экспортировать задачу в PDF
   *
   * @param  {String} uid задачи
   * @return {Promise}
   */
  async exportIssueToPdf(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("exportToPdf", reqData);
  }

  /**
   * Экспортировать задачу в Docx
   *
   * @param  {String} uid задачи
   * @return {Promise}
   */
  async exportIssueToDocx(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("exportToDocx", reqData);
  }

  /**
   * получить список прикрепленных связью  АИС объектов к активному объекту АИС
   *
   * @param  {String} uid активномого объекта АИС
   * @return {Promise}
   */
  async getAttachedObjects(uid) {
    const reqData = {
      variables: {
        uids: [uid]
      },
      query: `query ObjectsQuery ($uids: JSON!){
  Objects(
    ids_and_versions: $uids
  ) {
    ... on AisObject {
      uid
      class
      version
      links {
        uid
        class
        type {
          uid
          class
          name
          sourceCaption
          destCaption
        }
        source {
          uid
          class
          version
          title
          members {
            kindUid
          }
          representation
          fullObjectPath {
            uid
            class
            version
            title
            members {
              kindUid
            }
          }
        }
        dest {
          uid
          class
          version
          title
          members {
            kindUid
          }
          representation
          fullObjectPath {
            uid
            class
            version
            title
            members {
              kindUid
            }
          }
        }
      }
    }
    ... on Object {
      id
      class
      version
    }
  }
}`
    };
    const data = await this.request("getAttachedObjects", reqData);
    return data && data.Objects;
  }
}

export default IssueApi;
