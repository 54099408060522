import BaseRedmineModel from "./baseRedmineModel";
import { observable, computed, action } from "mobx";
import moment from "moment";
import { CLS_FILE } from "~/core/constants/Classes";

/**
 * Модель прикрепленного файла к задаче.
 *
 * @class AttachmentModel
 */
class AttachmentModel extends BaseRedmineModel {
  /**
   * id файла в хранилище  АИС
   *
   * @type String
   */
  @observable
  aisId = undefined;

  /**
   * Название файла
   *
   * @type String
   */
  @observable
  name = undefined;

  /**
   * contentType файла
   *
   * @type String
   */
  @observable
  contentType = undefined;

  /**
   * Размер файла в байтах
   *
   * @type String
   */
  @observable
  size = undefined;

  /**
   * Дата загрузки файла в хранилище
   *
   * @type String
   */
  @observable
  uploadDate = undefined;

  /**
   * md5 файла
   *
   * @type {String}
   */
  @observable
  md5 = undefined;

  /**
   * Ссылка на внешний источник
   *
   * @type {String}
   */
  externalUrl = undefined;


  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.aisId id файла в хранилище  АИС
   * @param {String} params.redmineId id файла в хранилище Redmine
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {AttachmentModel}
   */
  static create({
    aisId,
    redmineId,
    metadata,
    externalUrl
  }, issueStore) {
    return new AttachmentModel({
      aisId,
      redmineId,
      externalUrl,
      metadata
    }, issueStore);
  }

  constructor(params, issueStore) {
    super({
      id: params.redmineId
    }, issueStore);

    this.aisId = params.aisId;
    this.externalUrl = params.externalUrl;
    if (params.metadata) {
      this.setMetadata(params.metadata);
    }
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "issueAttachmentModel";
  }


  /**
   * Строковое представление названия файла
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name || this.aisId;
  }

  /**
   * Флаг, указывающий если связь файла в хранилище Redmine c файлом в хранилище АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.aisId;
  }

  /**
   * id файла в хранилище Redmine
   *
   * @return {Boolean}
   */
  @computed
  get redmineId() {
    return this.id;
  }

  @computed
  get fileIconItem() {
    return {
      type:        CLS_FILE,
      contentType: this.contentType
    };
  }

  @action
  setMetadata(data) {
    this.contentType = data.contentType;
    this.name = data.name;
    this.size = data.size;
    this.md5 = data.md5;
    this.uploadDate = data.uploadDate && moment(data.uploadDate).toDate();
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  @computed
  get config() {
    const cfg = {
      aisId:       this.aisId,
      redmineId:   this.id,
      externalUrl: this.externalUrl
    };

    if (this.contentType) {
      return {
        ...cfg,
        metadata: {
          contentType: this.contentType,
          name:        this.name,
          size:        this.size,
          md5:         this.md5,
          uploadDate:  this.uploadDate
        }
      };
    }

    return cfg;
  }
}

export default AttachmentModel;
