import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import moment from "moment";
import SplitPane, { Pane } from "react-split-pane";
import { Components, Field } from "@ais3p/ui-framework";

import { IssueModel } from "../../models";
import TimeLine from "../timeline";
import DescriptionEditor from "../editor/DescriptionEditor";
import ContentEditable from "../editor/ContentEditable";
import PrioritiesField from "../customFields/PrioritiesFiled";
import Attachments from "../attachments";
import UserItem from "../user/UserItem";
import JournalPanel from "../journal";
import AttributesPanel from "../attributes/AttributesPanel";
import RelatedIssues from "../relatedIssues/RelatedIssues";
import IssueStore from "../../stores/IssueStore";
import CustomFields from "../customFields/CustomFileds";


const MIN_TOOL_WIDTH = "70%";
/**
 * Панель для отображения/редактирования Задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueModel} params.issue задача
 * @param {IssueStore} params.store хранилизе для работы с задачами
 * @param {Boolean} params.canBeEditable флаг возмжности редактивраония Задачи
 * @param {Function} params.changeContext ф-я, которую необходимо вызвать, 
 *                    чтобы обновился контекст для вновь созданной задачи
 * @param {Object} params.layoutItem объект layoutItem
 */
const IssuePanel = observer((props) => {
  const { issue, store, canBeEditable, changeContext, layoutItem, onCreateIssue, onCancel } = props;
  const [splitSize, setSplitSize] = useState(MIN_TOOL_WIDTH);
  const [subIssuesMap, setSubIssuesMap] = useState(new Map());
  const [isLoadingSubIssues, setIsLoadingSubIssues] = useState(false);

  const onSplitPosChange = useCallback((size) => {
    setSplitSize(size);
  }, []);

  useEffect(async() => {
    setIsLoadingSubIssues(true);
    try {
      const uids = issue.relatedIssues
        .filter((subIssue) => {
          return subIssue.uid !== issue.uid;
        })
        .map((issue) => {
          return issue.uid;
        });
      if (uids && uids.length > 0) {
        const issues = await store.loadIssuesByUids({ issue: uids });
        if (Array.isArray(issues) && issues.length > 0) {
          setSubIssuesMap(new Map(issues.map((i) => {
            return [i.uid, i];
          })));
        } else {
          setSubIssuesMap(new Map());
        }
      }
    } finally {
      setIsLoadingSubIssues(false);
    }
  }, [issue.relatedIssues && issue.relatedIssues.length]);

  const canClose = useMemo(() => {
    if (isLoadingSubIssues) {
      return false;
    }
    if (subIssuesMap.size === 0) {
      return true;
    }
    // если у всех вложенных задач статус Закрыт, то тогда можно закрывать и текущую задачу
    return Array.from(subIssuesMap.values()).every((issue) => {
      return issue.status && issue.status.isClosed;
    });
  }, [subIssuesMap.size, isLoadingSubIssues]);

  
  const values = useMemo(() => {
    if (issue.isEditingMode) {
      return issue.tmpValues || {};
    }

    return issue;
  }, [
    issue,
    issue && issue.isEditingMode,
    issue && issue.tmpValues,
    issue && issue.projectUid
  ]);

  /**
   * Список проектов - options для выпадающего списка со списком проектов
   */
  const projects = useMemo(() => {
    return store.projectList.map((pr) => {
      return { label: pr.title, value: pr.uid, icon: pr.icon };
    });
  }, [store.projectList && store.projectList.length]);

  /**
   * Список статусов - options для выпадающего списка со статусами задач
   */
  const statuses = useMemo(() => {
    // Если у Задачи определен набор доступных статусов, то отображаем его
    if ((issue && Array.isArray(issue.allowedStatuses) && issue.allowedStatuses.length > 0)) {
      return issue.allowedStatuses.map((st) => {
        return { label: st.name, value: st.id };
      });
      return; 
    }

    // Если не задан модель Задачи или у Задачи не определен набор доступных статусов, то
    // отображаем все статусы
    return store.statusList.map((st) => {
      return { label: st.name, value: st.id };
    });
  }, [
    store.statusList,
    issue && issue.isNew, 
    issue && issue.allowedStatuses
  ]);

  const categories = useMemo(() => {
    const project = store.getProjectByUid(values.projectUid);
    if (!project) {
      return [];
    }
    if (!project.categories) {
      return [];
    }

    if (project.categories.length === 0) {
      return [];
    }
    return [{ label: "Без категории", value: "" }]
      .concat(issue.project.categories.map((cat) => {
        return {
          label: cat.name,
          value: cat.id
        };
      }));
  }, [values.projectUid]);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      issue && issue.setTmpValue(name, value);
    },
    [
      issue, 
      issue && issue.tmpValues
    ]
  );

  const onChangeField = useCallback(
    (value, name) => {
      switch (name) {
        case "projectUid":{
          issue && issue.setTmpValue(name, value);
          // Сбрасываем категорию, тк у каждого проекта свой набор категорий
          issue && issue.setTmpValue("categoryId", null);
          break;
        }
        case "startDate":
        case "dueDate":
          if (value) {
            issue && issue.setTmpValue(name, moment(value).toDate());  
          } else {
            issue && issue.setTmpValue(name, value);  
          }
          // issue && issue.setTmpValue(`${name}Uid`, value);
          break;
        case "watchers":{
          const { addWatchers, deleteWatchers } = issue.tmpValues;
          addWatchers.clear();
          deleteWatchers.clear();
          // проверяем на добавление нового пользователя
          value.forEach((userUid) => {
            if (!issue.watchers.has(userUid)) {
              addWatchers.add(userUid);
            }
          });
          // теперь проверям на удаленного пользователя
          issue.watcherUids.forEach((userUid) => {
            if (value && !value.includes(userUid)) {
              deleteWatchers.add(userUid);
            }
          });
          
          issue.setTmpValue("watcherUids", value);
          issue.setTmpValue("addWatchers", addWatchers);
          issue.setTmpValue("deleteWatchers", deleteWatchers);

          break;
        }
        default:
          issue && issue.setTmpValue(name, value);
      }
    },
    [issue]
  );

  const onChangeAttributes = useCallback(
    (attributes, isValid) => {
      issue && issue.setTmpValue("attributes", { attributes, isValid });
    },
    [issue && issue.tmpValues]
  );

  const onAddFile = useCallback(
    (attachment) => {
      const { addAttachments, deleteAttachments } = issue.tmpValues;
      addAttachments.push(attachment.aisId);
      const index = deleteAttachments.indexOf(attachment.aisId);
      if (index >= 0) {
        deleteAttachments.splice(index, 1);
      }
      issue.setTmpValue("addAttachments", addAttachments);
      issue.setTmpValue("deleteAttachments", deleteAttachments);
    },
    [issue && issue.tmpValues]
  );

  const onDeleteFile = useCallback(
    (attachment) => {
      const { deleteAttachments, addAttachments } = issue.tmpValues;
      const index = addAttachments.indexOf(attachment.aisId);
      if (index >= 0) {
        addAttachments.splice(index, 1);
      } else {
        deleteAttachments.push(attachment.aisId);
      }

      issue.setTmpValue("addAttachments", addAttachments);
      issue.setTmpValue("deleteAttachments", deleteAttachments);
    },
    [issue && issue.tmpValues]
  );

  const onSaveChanges = useCallback(async() => {
    issue && issue.setIsPending(true);
    try {
      const { isNew } = issue;
      const b = await store.saveIssue(issue, issue.jsonTmpValues);
      if (b) {
        issue.setIsEditingMode(false);
        if (isNew) {
          // необходимо, чтобы обновился контекст для вновь созданной задачи
          changeContext && changeContext(issue);
          onCreateIssue && onCreateIssue(issue);
        }
      }
    } catch (e) {
      store.onError(e);
    } finally {
      issue && issue.setIsPending(false);
    }
  }, [issue, layoutItem, onCreateIssue]);

  const onCancelChanges = useCallback(() => {
    if (issue?.isNew && onCancel) {
      onCancel(issue);
    } else {
      issue.setIsEditingMode(false);
    }
  }, [store, issue, issue?.isNew, onCancel]);

  const watcherOptions = useMemo(() => {
    if (issue.isExternal) {
      return Array.from(issue.watchers?.values()).map((u) => {
        return { label: u.shortName, value: u.id };
      });
    }
    return store.linkedUserList.map((user) => {
      return { label: user.aisUser.shortName, value: user.uid };
    });
  }, [
    issue.isEditingMode, 
    store.linkedUserList && store.linkedUserList.length, 
    issue.watchers,
    issue.isExternal
  ]);

  const goToInstance = useCallback(() => {
    window.open(issue.externalUrl, "_blank").focus();
    // Object.assign(document.createElement("a"), {
    //   target: "_blank",
    //   rel:    "noopener noreferrer",
    //   href:   issue.externalUrl
    // }).click();
  }, [issue && issue.externalUrl]);

  const dataPickerPortalId = "issues-datapicker-portal-id";

  return (
    <div className="issue-panel">
      {((issue && issue.isPending)) && (
        <Components.Preloader size={3} className="issue-panel-preloader" />
      )}
      {issue && (
        <div className="issue-panel-wrapper">
          <SplitPane
            split="vertical"
            minSize={!issue.isShownJournal ? "100%" :  MIN_TOOL_WIDTH}
            style={{ flex: 1 }}
            allowResize={issue.isShownJournal}
            onChange={onSplitPosChange}
            size={issue.isShownJournal ? splitSize : "100%"}
          >
            <div className="issue-panel-body">
              <div id={dataPickerPortalId} />
              <div className="issue-panel-header block-divider">
                <div className="issue-panel-header-left">
                  <div className="issue-panel-header-title">
                    <div className="issue-panel-header-title-num">
                      <span>{issue.titlePrefix}</span>
                      {issue.isExternal && issue.externalUrl &&
                        <Components.Tooltip content={"Перейти к источнику"}>
                          <Components.Icon 
                            name="relink-M"
                            className="issue-panel-header-title-url" 
                            onClick={goToInstance}
                          />
                        </Components.Tooltip>
                      }
                    </div>
                    <ContentEditable
                      name="subject"
                      className={classNames("issue-panel-header-title-subject", {
                        editable: issue.isEditingMode,
                        invalid:
                        issue.isEditingMode &&
                        !IssueModel.validateSubject(values.subject).isValid
                      })}
                      readOnly={!issue.isEditingMode}
                      isValid={IssueModel.validateSubject(values.subject).isValid}
                      text={values.subject}
                      onChange={onChange}
                    />
                  </div>
                </div>
                {(values.assignedToUid || (issue.isExternal && issue.assignedTo?.shortName)) &&
                <div className="issue-panel-header-right">
                  <UserItem
                    className="issue-panel-header-right-assignedTo"
                    userUid={values.assignedToUid}
                    store={store}
                    name={issue.isExternal && issue.assignedTo?.shortName}
                  />
                </div>
                }
              </div>

              <div className="issue-panel-statusbar block-divider">
                <div className="issue-panel-statusbar-item project">
                  <Field.SingleSelect
                    icon=""
                    label="Проект"
                    name="projectUid"
                    value={values.projectUid}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                    isValid={!!values.projectUid ? undefined : false}
                    placeholder="Выберите проект"
                    options={projects}
                  />
                </div>
                {
                  issue && !issue.isNew && 
                    <div className="issue-panel-statusbar-item issue-status-container">
                      <Field.SingleSelect
                        icon=""
                        label="Статус"
                        name="statusId"
                        value={values.statusId}
                        onChange={onChangeField}
                        isDisabled={!issue.isEditingMode}
                        isValid={!!values.statusId ? undefined : false}
                        placeholder="Выберите статус"
                        options={statuses}
                      />
                      {issue.isEditingMode && !canClose &&
                      <Components.Tooltip
                        content="Данная задача не может быть закрыта, тк у нее есть открытые подзадачи"
                      >
                        <div className="issue-status-container-warning">
                          <Components.Icon
                            name="warning-M"
                          />
                        </div>
                      </Components.Tooltip> 
                      }
                    </div>
                }
                {
                  <div className="issue-panel-statusbar-item pririty">
                    <PrioritiesField
                      // className={classNames({
                      //   invalid: issue.isEditingMode && !values.priorityId
                      // })}
                      icon=""
                      label="Приоритет"
                      name="priorityId"
                      placeholder="Приоритет"
                      readOnly={!issue.isEditingMode}
                      useColor={true}
                      onChange={onChangeField}
                      value={values.priorityId}
                      isValid={!!values.priorityId ? undefined : false}
                      options={store.priorityList.map((pr) => {
                        return { label: pr.name, value: pr.id, color: pr.color };
                      })}
                    />
                  </div>
                }
                {
                  categories.length > 0 &&
                  <div className="issue-panel-statusbar-item category">
                    <Field.SingleSelect
                      icon=""
                      label="Категория"
                      name="categoryId"
                      value={values.categoryId}
                      onChange={onChangeField}
                      isDisabled={!issue.isEditingMode}
                      placeholder="Выберите категорию"
                      options={categories}
                    />
                  </div>
                }
              </div>
              <DescriptionEditor
                name="description"
                store={store}
                canBeEditable={canBeEditable && issue.isEditingMode}
                issue={issue}
                text={values.description}
                isValid={
                  IssueModel.validateDescription(values.description).isValid
                }
                className={classNames("block-divider", {
                  invalid:
                  issue.isEditingMode &&
                  !IssueModel.validateDescription(values.description).isValid
                })}
                onChange={onChange}
              />
              <div className="block-divider">
                <div className="issue-panel-timeline">
                  <TimeLine
                    startDate={values.startDate}
                    endDate={values.dueDate}
                  />
                </div>
              </div>
              <div className="block-divider flex-gap">
                <div className="issue-panel-timedata">
                  <Field.DateTime
                    label="Создано"
                    name="createdOn"
                    className={"timedate"}
                    value={values.createdOn}
                    onChange={onChangeField}
                    isDisabled={true}
                    isClearable={false}
                  />
                  <Field.DateTime
                    label="Обновлено"
                    name="updatedOn"
                    className={"timedate"}
                    value={values.updatedOn}
                    onChange={onChangeField}
                    isDisabled={true}
                    isClearable={false}
                  />
                  <Field.DateTime
                    label="Дата начала"
                    name="startDate"
                    // isValid={(issue.isEditingMode && !values.startDate) ? false : undefined}
                    value={values.startDate}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                    isClearable={issue.isEditingMode}
                    portalId={dataPickerPortalId}
                  />
                  <Field.DateTime
                    label="Завершить до"
                    name="dueDate"
                    className={"timedate"}
                    isValid={(issue.isEditingMode && !!values.dueDate &&
                      values.startDate > values.dueDate) ? false : undefined}
                    minDate={!!values.startDate && values.startDate}
                    value={values.dueDate}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                    isClearable={issue.isEditingMode}
                    portalId={dataPickerPortalId}
                  />
                  <Field.Number
                    label="Оценка временных затрат, ч"
                    name="estimatedHours"
                    min={0}
                    icon="waiting-M"
                    className="timedate"
                    value={values.estimatedHours}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                  />
                  <Field.Number
                    label="Готовность, %"
                    name="doneRatio"
                    value={values.doneRatio}
                    min={0}
                    max={100}
                    step={1}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                  />
                </div>
                <div className="issue-panel-users">
                  {issue.readOnly &&
                    <React.Fragment>
                      <Field.String
                        icon="user-M"
                        label="Автор"
                        name="authorUid"
                        value={issue.author && (issue.author.aisUser?.shortName || issue.author.shortName)}
                        isDisabled={true}
                      />
                      <Field.String
                        icon="user-M"
                        label="Исполнитель"
                        name="assignedToUid"
                        value={issue.assignedTo && (issue.assignedTo.aisUser?.shortName || issue.assignedTo.shortName)}
                        isDisabled={true}
                      />
                    </React.Fragment>
                  }
                  {!issue.readOnly &&
                    <React.Fragment>
                      <Field.SingleSelect
                        icon="user-M"
                        label="Автор"
                        name="authorUid"
                        value={values.authorUid}
                        onChange={onChangeField}
                        isDisabled={true}
                        isValid={(issue.isEditingMode && !values.authorUid) ? false : undefined}
                        // отображаем только связанны пользователей Redmine с АИС
                        options={store.linkedUserList.map((user) => {
                          return { label: user.aisUser.shortName, value: user.uid };
                        })}
                      />
                      <Field.SingleSelect
                        icon="user-M"
                        label="Исполнитель"
                        name="assignedToUid"
                        // isValid={(issue.isEditingMode && !values.assignedToUid) ? false : undefined}
                        value={values.assignedToUid}
                        onChange={onChangeField}
                        isDisabled={!issue.isEditingMode}
                        isClearable={true}
                        placeholder="Выберите исполнителя"
                        // отображаем только связанных пользователей Redmine с АИС
                        options={store.linkedUserList.map((user) => {
                          return { label: user.aisUser.shortName, value: user.uid };
                        })}
                      />
                    </React.Fragment>
                  }
                  <Field.MultiSelect
                    icon="app-usersgroups-M"
                    label="Подписчики"
                    name="watchers"
                    // isValid={!(issue.isEditingMode && !values.assignedTo)?false:undefined}
                    value={values.watcherUids}
                    onChange={onChangeField}
                    isDisabled={!issue.isEditingMode}
                    isClearable={true}
                    placeholder="Выбрать"
                    multiselect={true}
                    // отображаем только связанных пользователей Redmine с АИС
                    options={watcherOptions}
                  />
                </div>
              </div>
              {issue && 
                <CustomFields 
                  issue={issue}
                  store={store}
                  values={values}
                  readOnly={!issue.isEditingMode}
                />
              }
              {issue && issue.isNew && (
                <div className="data-block block-divider issue-panel-attributes">
                  <AttributesPanel
                    issue={issue}
                    kindUid={issue.tracker && issue.tracker.uid}
                    isDisabled={!issue.isEditingMode}
                    onChange={onChangeAttributes}
                  />
                </div>
              )}
              <div className="data-block block-divider issue-panel-attachments">
                <Attachments
                  store={store}
                  issue={issue}
                  readOnly={!issue.isEditingMode}
                  onAddFile={onAddFile}
                  onDeleteFile={onDeleteFile}
                />
              </div>
              <div className="data-block block-divider issue-panel-related-issues">
                <RelatedIssues
                  store={store}
                  issue={issue}
                  subIssuesMap={subIssuesMap}
                  layoutItem={layoutItem}
                  readOnly={!issue.isEditingMode}
                />
              </div>
              {!issue.isNew &&
              <div className="data-block block-divider data-notes">
                <JournalPanel 
                  store={store} 
                  issue={issue} 
                  modeView="notes" 
                  className={classNames("issue-notes", {
                    "is-editing": issue.isEditingMode
                  })}
                />
                {issue.isEditingMode && 
                <DescriptionEditor
                  name="notes"
                  store={store}
                  canBeEditable={canBeEditable && issue.isEditingMode}
                  issue={issue}
                  text={values.notes}
                  label="Новый комментарий"
                  className={"issue-editor-notes"}
                  onChange={onChange}
                />
                }
              </div>
              }
              {canBeEditable && issue.isEditingMode && (
                <div className="issue-panel-buttons">
                  <Components.Button
                    onPress={onCancelChanges}
                    color="negative"
                    icon="cancel-M"
                    text={issue?.isNew ? "Отменить создание" : "Отменить изменения"}
                  />
                  <Components.Button
                    onPress={onSaveChanges}
                    color="positive"
                    icon="save-M"
                    isDisabled={!issue.isTmpValuesValid || !issue.hasChanges}
                    isLoading={issue.isPending}
                    text={issue?.isNew ? "Создать" : "Сохранить"}
                  />
                </div>
              )}
            </div>

            <Pane
              className={classNames(
                "issues-sidebar",
                "issues-panel",
                "issues-right-panel"
              )}
            >
              <div className={classNames("issues-panel", "issue-journal")}>
                <JournalPanel store={store} issue={issue} />
              </div>
            </Pane>
          </SplitPane>
        </div>
      )}
    </div>
  );
});

IssuePanel.propTypes = {
  issue:         PropTypes.instanceOf(IssueModel),
  store:         PropTypes.instanceOf(IssueStore).isRequired, 
  canBeEditable: PropTypes.bool, 
  changeContext: PropTypes.func,
  layoutItem:    PropTypes.object 
};

export default React.memo(IssuePanel);
